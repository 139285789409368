// @flow
import React from "react";
import {
  LineChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip, Line,
} from "recharts";
import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";
import type { ItemData } from "../../services/dashboardApi";
import LegendIcon from "../../icons/Legend";

export const formatDate = (v: string): string => v;
export const formatNumber = (v: number): string => (v >= 1000 ? `${v / 1000}k` : String(v));
export const formatValueNumber = (v: number): string => String(Number(v).toFixed(4));

type Props = {
  data: Array<ItemData>,
}

const defaultData = new Array(8).fill(dayjs().format("YYYY-MM-DD")).map((_, i) => ({
  date: dayjs().subtract(7 - i, "d").format("YYYY-MM-DD"),
  revenue: 0,
}));

const Chart = ({
  data,
}: Props) => {
  const commonAreaProps = {
    type: "monotone",
    strokeWidth: 2,
    strokeLinecap: "round",
    activeDot: true,
    isAnimationActive: false,
  };

  const dot1 = {
    dot: {
      r: 4,
      fill: "url(#stroke1)",
      strokeWidth: 3,
      stroke: "white",
      fillOpacity: 1,
    },
  };

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <Box pb={1} display="flex" alignItems="center" justifyContent="center">
        {
          payload.map((entry) => {
            const { dataKey, color, value } = entry;
            return (
              <Box key={`overlay-${dataKey}`} display="flex" alignItems="center" justifyContent="center">
                <LegendIcon color={color} />
                <Typography style={{ paddingLeft: "5px" }} variant="body1">
                  {value}
                  {" "}
                  $
                </Typography>
              </Box>
            );
          })
        }
      </Box>
    );
  };

  return (
    <ResponsiveContainer minHeight={150} minWidth={300} aspect={2}>
      <LineChart
        data={data.length ? data : defaultData}
        margin={{
          top: 30, right: 60, left: 0, bottom: 30,
        }}
      >
        <CartesianGrid />
        <Tooltip labelFormatter={formatDate} formatter={formatValueNumber} />
        <XAxis dataKey="date" tickFormatter={formatDate} />
        <YAxis tickFormatter={formatNumber} />
        <Legend
          verticalAlign="top"
          align="center"
          content={renderLegend}
        />

        <defs>
          <linearGradient id="stroke1">
            <stop stopColor="#4CAF50" />
          </linearGradient>
        </defs>

        <Line {...commonAreaProps} {...dot1} dataKey="revenue" name="Revenue" stroke="url(#stroke1)" />

      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
