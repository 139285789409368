// @flow
import type { Saga } from "redux-saga";
import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import { GET_CONTACT_INFO_SAGA } from "@fas/cpa-state-manager/redux/constants";
import setLoading from "@fas/cpa-state-manager/redux/actions/loading";
import {
  setContactInfoCompanyName,
  setContactInfoWebsite,
  setContactInfoAddress,
} from "@fas/cpa-state-manager/redux/actions";
import { getContactInfo, type ContactInfoResponse } from "../../services/profileApi";
import { catchError } from "../../actions/error/actions";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading("getContactInfoLoading", true));

    const response: ContactInfoResponse = yield call(getContactInfo);
    const {
      data: {
        companyName,
        companyUrl,
        address,
      },
    }: ContactInfoResponse = response;

    yield put(setContactInfoCompanyName(companyName));
    yield put(setContactInfoWebsite(companyUrl));
    yield put(setContactInfoAddress(address));
  }
  catch (error) {
    yield put(catchError(error));
  }
  finally {
    yield put(setLoading("getContactInfoLoading", false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_CONTACT_INFO_SAGA, (makeFetch: Function));
}
