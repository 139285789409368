// @flow

import type { DropDownObjItemType } from "@fas/cpa-state-manager/redux/reducers/dictionaries";
import { getDictionary } from "@fas/cpa-state-manager/services/selectors/dictionaries";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getDictionarySaga } from "@fas/cpa-state-manager/redux/actions/dictionaries/actions";
import getLoadingState from "@fas/cpa-state-manager/services/selectors/loading";
import { getDictionaryLoadingKey } from "../helpers/generators";

type UseDictionaryType = {
  loading: boolean,
  list: DropDownObjItemType[],
}

export default function useDictionary(dictionary: string): UseDictionaryType {
  const dispatch = useDispatch();
  const list: DropDownObjItemType[] = useSelector((state) => getDictionary(state, dictionary));
  const loading: boolean = useSelector((state) => getLoadingState(state, getDictionaryLoadingKey(dictionary)));

  useEffect(() => {
    if (!loading) {
      dispatch(getDictionarySaga(dictionary));
    }
  }, []);

  return {
    list,
    loading,
  };
}
