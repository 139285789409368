// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";

export type Fields = ("date"
  | "revenue"
  | "p1"
  | "hits"
  | "conversions"
  | "cr"
  | "epc"
  | "cost"
)

export type Filters = {
  dateFrom: string, // "2021-01-30",
  dateTo: string, // "2021-01-30",
  dynamicFields?: string[]
};

export type FiltersApi = {
  date: [{
    from: string, // "2021-01-30",
    to: string, // "2021-01-30",
  }],
  [string]: []
};

export type Params = {
  limit: number,
  page: number,
  sorting?: {
    current: string,
    direction: string,
  },
  filters: FiltersApi,
  fields: Fields[],
}

export type ItemData = {|
  date: string,
  revenue: number,
|}

export type TotalData = {|
  revenue: number,
|};

export type Data = {
  data: ItemData[],
  total: TotalData[],
}

export const getDashboard = (params: Params): PromiseResponse<Params, Data> => requestService
  .post(environment.endpoints.post.getDashboardData, {
    ...params,
  }).then(({ data }) => data);
