// @flow
import React, {
} from "react";
import {
  CircularProgress, Grid, Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import BlockIcon from "@mui/icons-material/Block";

type Props = {
  loading?: boolean,
}

const useStyles = makeStyles((theme) => ({
  info: {
    color: theme.palette.action.active,
  },
}));

const NoAvailableData = ({ loading }: Props) => {
  const classes = useStyles();
  return (
    <Grid item container justifyContent="center" alignItems="center" className={classes.info}>
      { loading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid item container justifyContent="center" alignItems="center">
            <BlockIcon style={{ fontSize: 60 }} />
          </Grid>
          <Grid item>
            <Typography variant="h4">no available data</Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

NoAvailableData.defaultProps = {
  loading: false,
};

export default NoAvailableData;
