// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table";
import type { State } from "../../store";
import type { Field } from "../../reducers/transactionReport/reducer";
import type { ItemData } from "../../services/mainReportApi";

export const getMainReportFields: OutputSelector<
  State,
  void,
  Field<ItemData>[]
> = createSelector(
  (state: State): Field<ItemData>[] => state.mainReport.get("fields", []),
  // eslint-disable-next-line no-unused-vars,max-len
  (value: Field<ItemData>[]): Field<ItemData>[] => value.filter(({ visible }) => visible).map(({ visible, ...item }) => item)
);
export const getMainReportGroupBy: OutputSelector<
  State,
  void,
  string,
> = createSelector(
  (state: State): string => state.mainReport.get("groupBy", "day"),
  (value: string): string => value
);

export const getMainReportIsAdvanced: OutputSelector<
  State,
  void,
  boolean
> = createSelector(
  (state: State): boolean => state.mainReport.get("isAdvanced", false),
  (value: boolean): boolean => value
);

export const getMainReportExportFilters: OutputSelector<
  State,
  void,
  Filters
> = createSelector(
  (state: State): Filters => state.mainReport.get("exportFilters", {}),
  (value: Filters): Filters => value
);
