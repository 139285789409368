// @flow
import React, { type StatelessFunctionalComponent, useEffect } from "react";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import {
  Box, IconButton, type Theme, Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getUserInfo as getUserInfoAction } from "@fas/cpa-state-manager/redux/actions";
import { ExpandLess } from "@mui/icons-material";
import { getUserInfoField } from "@fas/cpa-state-manager/services/selectors/userInfo";
import type { StoreWithUserInfo } from "@fas/cpa-state-manager/redux/containers/UserInfo/types/UserInfo.types";
import type {
  DispatchToProps, OwnProps, Props, StateToProps,
} from "./types/UserInfo.types";

const mapStateToProps: (StoreWithUserInfo) => StateToProps = (state: StoreWithUserInfo) => ({
  id: getUserInfoField(state, "id"),
  name: getUserInfoField(state, "name"),
});

const mapDispatchToProps: (Dispatch<*>) => DispatchToProps = (dispatch) => bindActionCreators({
  onGetUserInfo: getUserInfoAction,
}, dispatch);

const useStyles = makeStyles((
  theme: Theme
) => ({
  layout: {
    minWidth: theme.spacing(20),
    padding: theme.spacing(2),
  },
  profileName: {
    color: theme.palette.text.title,
    fontSize: "14px",
    lineHeight: "18px",
    whiteSpace: "break-spaces",
    overflowWrap: "break-word",
  },
  profileId: {
    color: theme.palette.text.disabled,
    fontSize: "12px",
    lineHeight: "18px",
  },
}));

const UserInfo: StatelessFunctionalComponent<Props> = ({
  onGetUserInfo,
  onToggleExpand,
  name,
  id,
}: Props) => {
  const classes = useStyles();
  useEffect(() => {
    onGetUserInfo();
  }, [onGetUserInfo]);

  return (
    <Box className={classes.layout}>
      <Box width={1} display="flex" flexDirection="row" justifyContent="flex-start">
        <Box width={1} display="flex" flexDirection="column" alignItems="flex-start">
          <Typography data-testid="user-info-name" className={classes.profileName} component="div" variant="subtitle1">{name}</Typography>
          <Typography data-testid="user-info-id" className={classes.profileId} variant="subtitle1">{id}</Typography>
        </Box>
        <Box>
          <IconButton size="small" onClick={onToggleExpand}>
            <ExpandLess />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(UserInfo);
