// @flow
import React from "react";

const LegendIcon = ({ color }: { color: string }) => (
  <svg width="33" height="13" viewBox="0 0 33 13" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M1 7.5C0.447715 7.5 0 7.05228 0 6.5C0 5.94772 0.447715 5.5 1 5.5H32C32.5523 5.5 33 5.94772 33 6.5C33 7.05228 32.5523 7.5 32 7.5H1Z" />
    <path d="M16.5 12C19.5376 12 22 9.53757 22 6.5C22 3.46243 19.5376 1 16.5 1C13.4624 1 11 3.46243 11 6.5C11 9.53757 13.4624 12 16.5 12Z" stroke="white" strokeWidth="2" />
  </svg>
);

export default LegendIcon;
