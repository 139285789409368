// @flow
import type { Saga } from "redux-saga";
import {
  put,
  call,
  takeEvery,
} from "redux-saga/effects";
import { GET_MANAGER_INFO } from "@fas/cpa-state-manager/redux/constants";
import { setManagerInfo } from "@fas/cpa-state-manager/redux/actions";
import ManagerApi from "../../services/managerApi";
import type { ManagerInfoPayload } from "../../services/managerApi/manager";
import { catchError } from "../../actions/error/actions";

export function* makeFetch(): Saga<void> {
  try {
    const {
      data: {
        name, email, skype, telegram,
      },
    }: ManagerInfoPayload = yield call(ManagerApi.getManagerInfo);

    yield put(setManagerInfo({
      name,
      title: "",
      avatarUrl: "",
      contacts: {
        email,
        telegram,
        skype,
      },
    }));
  }
  catch (error) {
    yield put(catchError(error));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_MANAGER_INFO, (makeFetch: Function));
}
