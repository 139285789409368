// @flow

import ButtonBaseComponent from "./ButtonBaseComponent";

const themeDefaultObject: mixed = {
  theme: "default",
  typography: {
    fontFamily: [
      "Montserrat",
      "serif",
    ].join(","),
    body1: {
      fontSize: "14px",
      lineHeight: "18px",
    },
    body2: {
      fontSize: "12px",
      lineHeight: "18px",
    },
    h2: {
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: "39px",
    },
    h4: {
      fontSize: "18px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
    h5: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "bold",
    },
    h6: {
      fontSize: "16px",
      lineHeight: "20px",
    },
    gradient: {
      background: "linear-gradient(96.34deg, #403CF4 0%, #23B480 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    gradientTitle: {
      fontWeight: "bold",
      fontSize: "50px",
      background: "linear-gradient(96.34deg, #403CF4 0%, #23B480 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
  },
  shape: {
    borderRadius: 0,
  },
  buttons: {
    main: {},
    secondary: {},
  },
  palette: {
    primary: {
      main: "#403CF4",
    },
    background: {
      default: "#fff",
      gradient: "linear-gradient(96.34deg, rgba(64, 60, 244, 0.1) 0%, rgba(35, 180, 128, 0.1) 100%)",
    },
    text: {
      primary: "#000000",
    },
    error: {
      main: "#F14D6B",
    },
    icons: {},
  },
  components: {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: { shrink: true },
        variant: "standard",
        size: "small",
      },
      styleOverrides: {
        root: {
          "&>.MuiInput-root": {
            paddingTop: "6px",
            paddingBottom: "5px",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          paddingTop: "6px",
          paddingBottom: "6px!important",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: "8px",
          paddingBottom: "8px",
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "standard",
        size: "small",
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          lineHeight: "20px",
          border: "1px solid rgba(0, 0, 0, 0.1)",
        },
        head: {
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "20px",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&$disabled:before": {
            borderBottomStyle: "solid",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "underline": {
          },
          "&.Mui-disabled": {
            cursor: "text",
          },
          fontSize: "16px",
          lineHeight: "24px",
        },
        root: {
          fontSize: "16px",
          lineHeight: "24px",
          "&&&:before": {
            borderBottomStyle: "solid",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: "translate(0, -6px) scale(1)",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#000",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "24px",
          "&.Mui-disabled": {
            color: "#000",
          },
          "&.Mui-focused": {
            color: "#000",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        marginDense: {
          fontWeight: 500,
          fontSize: "12px",
          lineHeight: "18px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          color: "rgba(0, 0, 0, 0.3)",
          "&$checked": {
            color: "#1E88E5",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        component: ButtonBaseComponent,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
        },
        containedPrimary: {
          paddingTop: "10px",
          paddingBottom: "10px",
          minWidth: "max-content",
          lineHeight: "20px",
          fontSize: "14px",
          fontWeight: 700,
          background: "linear-gradient(96.34deg, #403CF4 0%, #23B480 100%)",
          color: "white",
          "&:hover": {
            background: "linear-gradient(96.34deg, #403CF4 0%, #23B480 100%)",
            boxShadow: "none",
          },
          "&:disabled": {
            color: "#ccc",
            boxShadow: "none",
          },
          boxShadow: "none",
        },
        containedSecondary: {
          paddingTop: "10px",
          paddingBottom: "10px",
          minWidth: "max-content",
          lineHeight: "20px",
          fontSize: "14px",
          fontWeight: 700,
          backgroundColor: "none",
          background: "linear-gradient(96.34deg, rgba(64, 60, 244, 0.1) 0%, rgba(35, 180, 128, 0.1) 100%), #FFFFFF",
          color: "#1E88E5",
          "&:hover": {
            background: "linear-gradient(96.34deg, rgba(64, 60, 244, 0.1) 0%, rgba(35, 180, 128, 0.1) 100%), #FFFFFF",
            boxShadow: "none",
            backgroundColor: "none",
          },
          boxShadow: "none",
        },
      },
    },
  },
};

export default themeDefaultObject;
