// @flow
import React, {
  type Node,
} from "react";
import {
  TableCell,
  TableRow,
  type Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import type {
  Column,
} from "./types/TableComponent.types";

type Props<T> = {
  columns: Array<Column<T>>,
  row: T,
  // eslint-disable-next-line react/require-default-props
  classes?: { cell?: string, row?: string },
}

type Classes = {
  cell: string,
}

const useStyles: () => Classes = makeStyles((theme: Theme): {[key: $Keys<Classes>]: mixed} => ({
  cell: {
    color: theme.palette.text.primary,
    padding: 10,
    paddingBottom: theme.spacing(2),
  },
}));

function Row<T: Object>({
  columns,
  row,
  classes = {},
}: Props<T>): Node {
  const ownClasses: Classes = useStyles();

  return (
    <TableRow
      hover
      aria-checked={false}
      tabIndex={-1}
      data-testid="table-component-table-row"
      className={clsx(classes.row)}
    >
      {columns.map((column: Column<T>): Node => (
        <TableCell
          size="small"
          key={column.key}
          className={clsx(ownClasses.cell, classes.cell, column.className)}
          colSpan={column.colSpan || 1}
        >
          {column.render ? column.render({ row, column }) : String(row[column.field])}
        </TableCell>
      ))}
    </TableRow>
  );
}

const useStylesTotal = makeStyles((theme) => ({
  cell: {
    textAlign: "center",
    paddingBottom: theme.spacing(1),
    fontSize: "18px",
    fontWeight: "700",
    ...theme.typography.gradient,
  },
  row: {
    background: "linear-gradient(0deg, rgba(63, 67, 238, 0.1), rgba(63, 67, 238, 0.1)), #FFFFFF",
  },
}));

export const TotalRow = <T: Object>(props: Props<T>) => <Row {...props} classes={useStylesTotal()} />;

export default Row;
