// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { CircularProgress, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import type { Classes, Props } from "./types/Loader.types";

const useStyles: () => Classes = makeStyles((): {[key: $Keys<Classes>]: mixed} => ({
  loaderWrapper: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "none",
    zIndex: 999,
    backgroundColor: "rgba(200, 200, 200, 0.5)",
  },
}));

const Loader: StatelessFunctionalComponent<Props> = ({
  loading,
  children,
  mt = 0,
}: Props) => {
  const classes: Classes = useStyles();

  return (
    <Box position="relative" mt={mt}>
      {loading && (
        <div className={classes.loaderWrapper}>
          <CircularProgress size={24} />
        </div>
      )}
      {children}
    </Box>
  );
};

export default Loader;
