// @flow
export const GET_DICTIONARY_SAGA: "GET_DICTIONARY_SAGA" = "GET_DICTIONARY_SAGA";
export const SET_DICTIONARY: "SET_DICTIONARY" = "SET_DICTIONARY";

export const DASHBOARD_TABLE: "dashboardTable" = "dashboardTable";

export const PAYOUT_HISTORY_TABLE: "PAYOUT_HISTORY_TABLE" = "PAYOUT_HISTORY_TABLE";
export const GET_PAYOUT_HISTORY_REPORT_SAGA: "GET_PAYOUT_HISTORY_REPORT_SAGA" = "GET_PAYOUT_HISTORY_REPORT_SAGA";

export const DOWNLOAD_MAIN_REPORT_SAGA: "DOWNLOAD_MAIN_REPORT_SAGA" = "DOWNLOAD_MAIN_REPORT_SAGA";
export const GET_MAIN_REPORT_SAGA: "GET_MAIN_REPORT_SAGA" = "GET_MAIN_REPORT_SAGA";
export const SET_MAIN_REPORT_FIELDS: "SET_MAIN_REPORT_FIELDS" = "SET_MAIN_REPORT_FIELDS";
export const SET_MAIN_REPORT_GROUP: "SET_MAIN_REPORT_GROUP" = "SET_MAIN_REPORT_GROUP";
export const SET_MAIN_REPORT_IS_ADVANCED: "SET_MAIN_REPORT_IS_ADVANCED" = "SET_MAIN_REPORT_IS_ADVANCED";
export const SET_MAIN_REPORT_EXPORT_FILTERS: "SET_MAIN_REPORT_EXPORT_FILTERS" = "SET_MAIN_REPORT_EXPORT_FILTERS";
export const MAIN_TABLE: "MAIN_TABLE" = "MAIN_TABLE";

export const GET_TRANSACTION_REPORT_SAGA: "GET_TRANSACTION_REPORT_SAGA" = "GET_TRANSACTION_REPORT_SAGA";
export const SET_TRANSACTION_REPORT_FIELDS: "SET_TRANSACTION_REPORT_FIELDS" = "SET_TRANSACTION_REPORT_FIELDS";
export const SET_TRANSACTION_REPORT_IS_ADVANCED: "SET_TRANSACTION_REPORT_IS_ADVANCED" = "SET_TRANSACTION_REPORT_IS_ADVANCED";
export const SET_TRANSACTION_REPORT_EXPORT_FILTERS: "SET_TRANSACTION_REPORT_EXPORT_FILTERS" = "SET_TRANSACTION_REPORT_EXPORT_FILTERS";
export const DOWNLOAD_TRANSACTION_REPORT_SAGA: "DOWNLOAD_TRANSACTION_REPORT_SAGA" = "DOWNLOAD_TRANSACTION_REPORT_SAGA";
export const TRANSACTION_TABLE: "TRANSACTION_TABLE" = "TRANSACTION_TABLE";

export const SET_CURRENT_PAYMENT_METHOD_ID: "SET_CURRENT_PAYMENT_METHOD_ID" = "SET_CURRENT_PAYMENT_METHOD_ID";

export const CATCH_ERROR: "CATCH_ERROR" = "CATCH_ERROR";
export const PAYMENT_METHOD_FORM: "PAYMENT_METHOD_FORM" = "PAYMENT_METHOD_FORM";
