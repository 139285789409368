// @flow
import React, {
  type StatelessFunctionalComponent, useState,
} from "react";
import DocumentTitle from "react-document-title";
import type { ContextRouter } from "react-router-dom";
import {
  Box, Button, TextField, Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { type AuthResponse, autologin, setAuthToken } from "../../services/request";
import { catchError } from "../../actions/error/actions";

type Props = {
  location: $PropertyType<ContextRouter, "location">,
  history: $PropertyType<ContextRouter, "history">,
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  input: {
    paddingBottom: theme.spacing(2),
  },
  title: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
    ...theme.typography.gradientTitle,
  },
}));

const Autologin: StatelessFunctionalComponent<Props> = ({ location, history }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    token: (new URLSearchParams(location.search)).get("token") || "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((data) => ({ ...data, [name]: value }));
    setErrorMessage("");
  };

  const onKeyPress = ({ charCode }: { charCode: number }) => {
    const enterCharCode: number = 13;
    if (charCode === enterCharCode) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    autologin(formData).then((r: AuthResponse) => {
      setAuthToken(r.payload.token, new Date(r.payload.expires * 1000));
      history.push("/");
    }).catch((r) => {
      if (!r.response) {
        dispatch(catchError(r));
      }
      else {
        const message: string = (r && r.response && r.response.data && r.response.data.message) || "Error";
        setErrorMessage(message);
      }
    }).finally(() => setLoading(false));
  };

  return (
    <DocumentTitle title="Affiliate Auto Login">
      <Box onKeyPress={onKeyPress} className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          Sign In
        </Typography>
        <TextField
          label="Email"
          fullWidth
          autoFocus
          placeholder="Input your email"
          type="text"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className={classes.input}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          name="next"
          onClick={handleSubmit}
          disabled={loading}
        >
          <Box width="100%">Login</Box>
        </Button>
      </Box>
    </DocumentTitle>
  );
};

export default Autologin;
