// @flow
/* eslint-disable import/max-dependencies */
import React, { type Node, type StatelessFunctionalComponent, useEffect } from "react";
import {
  Box, CircularProgress, TextField, type Theme, Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DocumentTitle from "react-document-title";
import { type TrackingCodeComponentProps, TrackingTools } from "@fas/cpa-state-manager";
import type { UseAdditionalPostbacksHook } from "@fas/cpa-state-manager/redux/hooks";
import { useAdditionalPostbacks } from "@fas/cpa-state-manager/redux/hooks";
import type { UseEventsPostback } from "@fas/cpa-state-manager/redux/hooks/useEventsPostback";
import { useEventsPostback } from "@fas/cpa-state-manager/redux/hooks/useEventsPostback";
import CollapseCard from "./CollapseCard";
import { TableComponent } from "../TableComponent";
import type { TableClasses } from "../TableComponent/types/TableComponent.types";
import type { Props } from "../../containers/Postbacks";
import type { Classes } from "./types/Postbacks.types";
import InformationalEventsPostbacks from "../InformationalEventsPostbacks";
import AdditionalPostbacks from "../AdditionalPostbacks";
import { disableUnderlineStyle } from "../InformationalEventsPostbacks/InformationalEventsPostbacks";

const useAdditionalPostbacksStyle = makeStyles((theme) => ({
  container: {
    "&>div": {
      alignItems: "start",
    },
    "& > div:nth-child(n+2) label.MuiFormLabel-root": {
      display: "none",
    },
  },
  item: {
    paddingTop: theme.spacing(1),
    ...disableUnderlineStyle,
  },
}));

const useStyles: () => Classes = makeStyles((theme: Theme): {[key: $Keys<Classes>]: mixed} => ({
  trackingCode: {
    ...disableUnderlineStyle,
  },
  text: {
    lineHeight: "31px",
  },
  link: {
    color: theme.palette.primary.main,
  },
  parameter: {
    "& pre": {
      margin: 0,
      color: theme.palette.primary.main,
    },
  },
  example: {
    "& pre": {
      margin: 0,
    },
  },
}));

const useTableStyles: () => TableClasses = makeStyles((theme: Theme): {[key: $Keys<TableClasses>]: mixed} => ({
  cell: {
    textAlign: "left",
    verticalAlign: "initial",
    ...theme.typography.body1,
  },
  headerCell: {
    textAlign: "left",
    color: theme.palette.primary.main,
  },
}));

const data: Array<{
  parameter: string,
  description: string,
  example: string | number,
}> = [
  {
    parameter: "{payout}",
    description: "Amount paid to you for conversion",
    example: 1.95,
  },
  {
    parameter: "{data2}",
    description: "Your click/transaction ID",
    example: "",
  },
];

const Postbacks: StatelessFunctionalComponent<Props> = ({
  isGetDefaultPostbackLoading,
  onGetTrackingCode,
}: Props) => {
  const classes: Classes = useStyles();
  const tableClasses: TableClasses = useTableStyles();

  const eventsPostback: UseEventsPostback = useEventsPostback();
  const isHaveInfoPostback: boolean = eventsPostback.data.length > 0;

  const additionalClasses = useAdditionalPostbacksStyle();
  const additionalPostback: UseAdditionalPostbacksHook = useAdditionalPostbacks();
  const isHaveAdditionalPostback: boolean = additionalPostback.data.length > 0;

  useEffect(() => {
    onGetTrackingCode();
  }, [onGetTrackingCode]);

  const columns = [
    {
      field: "parameter",
      label: "Parameter",
      key: "parameter",
      className: classes.parameter,
      render: ({ row }): Node => <pre>{row.parameter}</pre>,
    },
    {
      field: "description",
      label: "Description",
      key: "description",
    },
    {
      field: "example",
      label: "Example",
      key: "example",
      className: classes.example,
      render: ({ row: { example } }): Node => (typeof example === "number" ? <pre>{example}</pre> : example),
    },
  ];

  return (
    <DocumentTitle title="Postback URLs">
      <Box>
        <Box>
          <CollapseCard title="Default postback">
            <Box p={3} data-testid="default-postback">
              <TrackingTools
                renderComponent={({
                  trackingCode,
                  canEdit,
                }: TrackingCodeComponentProps): Node => (
                  <Box position="relative" data-testid="defaultPostback">
                    <TextField
                      className={classes.trackingCode}
                      value={trackingCode}
                      disabled={isGetDefaultPostbackLoading}
                      helperText='See "how to" block below for the details'
                      label="Tracking code"
                      fullWidth
                      InputProps={{
                        readOnly: !canEdit,
                      }}
                    />
                    {isGetDefaultPostbackLoading && (
                      <CircularProgress
                        size={24}
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "50%",
                        }}
                      />
                    )}
                  </Box>
                )}
              />
            </Box>
          </CollapseCard>
        </Box>
        {isHaveAdditionalPostback && (
          <Box mt={3}>
            <CollapseCard title="Additional postbacks" initialOpen={false}>
              <Box p={3} data-testid="additional-postback">
                <AdditionalPostbacks
                  data={additionalPostback.data}
                  isLoading={false}
                  classes={additionalClasses}
                />
              </Box>
            </CollapseCard>
          </Box>
        )}
        {isHaveInfoPostback && (
          <Box mt={3}>
            <CollapseCard title="Informational events postbacks" initialOpen={false}>
              <Box p={3}>
                <InformationalEventsPostbacks {...eventsPostback} />
              </Box>
            </CollapseCard>
          </Box>
        )}
        <Box mt={3}>
          <CollapseCard title="How to create own postback URLs">
            <Box p={3}>
              <Box mb={2}>
                <Typography gutterBottom className={classes.text}>
                  Here you can create your own postback URLs that will be sent any time you have a new transaction.
                </Typography>
                <Typography gutterBottom className={classes.text}>
                  The example of postback URL:
                  <span className={classes.link}>{"http://your-domain.com/tracking.php?payout={payout}&click_id={data2}"}</span>
                </Typography>
                <Typography gutterBottom className={classes.text}>
                  After creating the postback URL, ask your manager to approve it.
                  If the code is not approved, it will not work.
                </Typography>
              </Box>
              <TableComponent
                classes={tableClasses}
                columns={columns}
                data={data}
              />
            </Box>
          </CollapseCard>
        </Box>
      </Box>
    </DocumentTitle>
  );
};

export default Postbacks;
