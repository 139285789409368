// @flow
/* eslint-disable import/max-dependencies */
import {
  call, put, select, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSorting } from "@fas/cpa-state-manager/services/selectors/table";
import setLoading from "@fas/cpa-state-manager/redux/actions/loading/actions";
import type { Sorting } from "@fas/cpa-state-manager/redux/actions/table";
import {
  downloadCsv,
  prepareFieldsToExport,
  prepareFilters,
  prepareSorting,
} from "../../helpers/generators";
import { DOWNLOAD_TRANSACTION_REPORT_SAGA, TRANSACTION_TABLE } from "../../helpers/constants";
import { setTransactionReportTableFields } from "../../actions/transactionReport";
import { downloadTransactionReport } from "../../services/transactionReportApi";
import { catchError } from "../../actions/error/actions";
import { getTransactionReportExportFilters, getTransactionReportFields } from "../../selectors/transactionReport";
import type { Field } from "../../reducers/transactionReport/reducer";
import type { ItemData } from "../../services/transactionReportApi";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading("downloadTransactionReportLoading", true));
    const { dynamicFields, ...filters } = yield select(getTransactionReportExportFilters);

    const sorting: Sorting = yield select(getTableSorting, TRANSACTION_TABLE);
    yield put(setTransactionReportTableFields(dynamicFields));
    const fields: Field<ItemData>[] = yield select(getTransactionReportFields);

    const response: string = yield call(downloadTransactionReport, {
      filters: prepareFilters(filters),
      sorting: prepareSorting(sorting, fields),
      fields: prepareFieldsToExport(fields),
    });
    yield call(downloadCsv, response, "TransactionReport");
  }
  catch (error) {
    yield put(catchError(error));
  }
  finally {
    yield put(setLoading("downloadTransactionReportLoading", false));
  }
}

export default function* watchExportTransactionReportDataSaga(): Saga<void> {
  yield takeEvery(DOWNLOAD_TRANSACTION_REPORT_SAGA, (makeFetch: Function));
}
