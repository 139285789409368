// @flow
import React, {
  type StatelessFunctionalComponent, useState,
} from "react";
import { logoutRedirect } from "../../services/request";
import Header from "../Header";
import AppNavSidebar from "../AppNavSidebar";

type Props = {
  isShowAction: boolean,
}

const Navigation: StatelessFunctionalComponent<Props> = ({ isShowAction }) => {
  const [isExpand, setIsExpand] = useState<boolean>(true);

  const onToggleExpand: () => void = () => {
    setIsExpand(!isExpand);
  };

  return (
    <>
      <Header
        isShowAction={isShowAction}
        isExpand={isExpand}
        onToggleExpand={onToggleExpand}
        onLogout={logoutRedirect}
      />
      {isShowAction && (
        <AppNavSidebar
          isOpen={isExpand}
          onToggleExpand={onToggleExpand}
        />
      )}
    </>
  );
};

export default Navigation;
