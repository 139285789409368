// @flow
import environment from "environment";
import { requestService } from "../request";

export type Params = {
  filters: {
    created: [{
      from: string,
      to: string,
    }],
    [string]: [],
  },
  sorting?: {
    current: string,
    direction: string,
  },
  page: number,
  limit: number,
}

export type ItemData = {
  created: string,
  dateFrom: string,
  dateTo: string,
  payout: number,
  paidOn: string,
  paymentMethod: string,
  id: number,
  currency: string,
}

export type Data = {
  data: ItemData[],
  count: number,
}
export const getPayoutHistoryReportList: (params: Params) => Promise<Data> = (params) => requestService
  .post(environment.endpoints.post.getPayoutHistory, params)
  .then(({ data }: { data: Data }): Data => data);
