// @flow
import {
  GET_PAYOUT_HISTORY_REPORT_SAGA,
} from "../../helpers/constants";

export type GetPayoutHistoryReportDataSaga = {|
  type: typeof GET_PAYOUT_HISTORY_REPORT_SAGA,
|}

export type Actions = GetPayoutHistoryReportDataSaga

export const getPayoutHistoryReportDataSaga: () => GetPayoutHistoryReportDataSaga = () => ({
  type: GET_PAYOUT_HISTORY_REPORT_SAGA,
});
