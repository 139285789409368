// @flow
import React from "react";
import { SvgIcon } from "@mui/material";

const PostbackUrl = () => (
  <SvgIcon viewBox="0 0 24 24">
    <path d="M3 9V5C3 3.9 3.9 3 5 3H9V5H5V9H3Z" />
    <path d="M15 19H19V15H21V19C21 20.1 20.1 21 19 21H15V19Z" />
    <path d="M13.4849 14.8991L10.6565 17.7276C9.44734 18.9367 7.48159 18.9367 6.27244 17.7276C5.06328 16.5184 5.06328 14.5527 6.27244 13.3435L9.10086 10.5151L7.75736 9.17157L4.92893 12C2.97732 13.9516 2.97732 17.1195 4.92893 19.0711C6.88055 21.0227 10.0484 21.0227 12 19.0711L14.8284 16.2426L13.4849 14.8991Z" />
    <path d="M14.1213 8.46447L8.46447 14.1213L9.87868 15.5355L15.5355 9.87868L14.1213 8.46447Z" />
    <path d="M19.0711 4.92893C17.1195 2.97732 13.9516 2.97732 12 4.92893L9.17157 7.75736L10.5151 9.10086L13.3435 6.27244C14.5527 5.06328 16.5184 5.06328 17.7276 6.27244C18.9367 7.48159 18.9367 9.44734 17.7276 10.6565L14.8991 13.4849L16.2426 14.8284L19.0711 12C21.0227 10.0484 21.0227 6.88055 19.0711 4.92893Z" />
  </SvgIcon>
);

export default PostbackUrl;
