// @flow
import { CATCH_ERROR } from "../../helpers/constants";

export type CatchErrorType = {
  type: typeof CATCH_ERROR,
  error: *,
}

export function catchError(error: *): CatchErrorType {
  return {
    type: CATCH_ERROR,
    error,
  };
}
