// @flow
import React, {
} from "react";
import { Redirect, Route } from "react-router-dom";
import type { Node } from "react";
import { isAuthenticated } from "../../services/request";

type Props = {
  component: React$ComponentType<*>,
  path?: string | Array<string>,
  exact?: boolean,
}

const PrivateRoute = ({ component: Component, path, exact }: Props) => (
  <Route
    path={path}
    exact={exact}
    render={(props): Node => (isAuthenticated() ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: props.location },
        }}
      />
    ))}
  />
);

PrivateRoute.defaultProps = {
  path: "",
  exact: true,
};

export default PrivateRoute;
