// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import type { Node } from "react";
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table";
import { useDispatch, useSelector } from "react-redux";
import type { ContextRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Link } from "@mui/material";
import { changeTableFilters } from "@fas/cpa-state-manager/redux/actions/table";
import { TRANSACTION_TABLE } from "../../helpers/constants";
import { getMainReportExportFilters } from "../../selectors/mainReport";
import type { ItemData } from "../../services/mainReportApi";
import type { Column } from "../TableComponent/types/TableComponent.types";
import getFiltersFromRow from "./getFiltersFromRow";
import { getTransactionReportDataSaga } from "../../actions/transactionReport";

function ConversionButton({ row, column }: { row: ItemData, column: Column<ItemData> }): Node {
  const filters: Filters = useSelector(getMainReportExportFilters);
  const dispatch: <A>(A) => A = useDispatch();
  const history: $PropertyType<ContextRouter, "history"> = useHistory();
  if (!row[column.field]) {
    return row[column.field];
  }
  return (
    <>
      <Link
        data-testid="conversion-button"
        style={{ cursor: "pointer" }}
        onClick={() => {
          dispatch(changeTableFilters(TRANSACTION_TABLE, getFiltersFromRow(row, filters)));
          dispatch(getTransactionReportDataSaga());
          history.push("/transactionReport");
        }}
      >
        {row[column.field]}
      </Link>
    </>
  );
}
export default ConversionButton;
