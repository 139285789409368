// @flow
/* eslint-disable import/max-dependencies */
import {
  call, put, select, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setTableData, changeTableItemsTotalAmount } from "@fas/cpa-state-manager/redux/actions";
import { getTableFilters, getTablePage, getTablePageSize } from "@fas/cpa-state-manager/services/selectors/table";
import setLoading from "@fas/cpa-state-manager/redux/actions/loading/actions";
import { changeTablePage } from "@fas/cpa-state-manager/redux/actions/table";
import { getTableSorting } from "@fas/ui-framework/lib/redux/selectors/table";
import type { Sorting } from "@fas/cpa-state-manager/redux/actions/table";
import { GET_PAYOUT_HISTORY_REPORT_SAGA, PAYOUT_HISTORY_TABLE } from "../../helpers/constants";
import { getPayoutHistoryReportList } from "../../services/payoutHistoryReportApi";
import { catchError } from "../../actions/error/actions";
import { isEqualPreviousBuilder, prepareFilters } from "../../helpers/generators";
import type { FiltersApi } from "../../services/dashboardApi";

const isEqualPrevious = isEqualPreviousBuilder();

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading("getPayoutHistoryReportLoading", true));
    const filters = yield select(getTableFilters, PAYOUT_HISTORY_TABLE);

    if (!isEqualPrevious(filters)) {
      yield put(changeTablePage(PAYOUT_HISTORY_TABLE, 1));
    }

    const limit: number = yield select(getTablePageSize, PAYOUT_HISTORY_TABLE);
    const page: number = yield select(getTablePage, PAYOUT_HISTORY_TABLE);
    const sorting: Sorting = yield select(getTableSorting, PAYOUT_HISTORY_TABLE);

    const { date, ...preparedFilters }: FiltersApi = prepareFilters(filters);

    // $FlowFixMe entries return mixed type
    const [[current, direction] = []]: [[string, string]] = Object.entries(sorting);

    const { data, count } = yield call(getPayoutHistoryReportList, {
      sorting: current ? { current, direction } : undefined,
      filters: {
        ...preparedFilters,
        created: date,
      },
      limit,
      page,
      fields: [
        "id",
        "created",
        "dateFrom",
        "dateTo",
        "payout",
        "paidOn",
        "paymentMethod",
        "currency",
      ],
    });
    yield put(setTableData(PAYOUT_HISTORY_TABLE, data));
    yield put(changeTableItemsTotalAmount(PAYOUT_HISTORY_TABLE, count));
  }
  catch (error) {
    yield put(catchError(error));
  }
  finally {
    yield put(setLoading("getPayoutHistoryReportLoading", false));
  }
}

export default function* watchGetPayoutHistoryReportDataSaga(): Saga<void> {
  yield takeEvery(GET_PAYOUT_HISTORY_REPORT_SAGA, (makeFetch: Function));
}
