// @flow
import type { Saga } from "redux-saga";
import {
  put,
  call,
  takeEvery,
} from "redux-saga/effects";
import { GET_USER_INFO } from "@fas/cpa-state-manager/redux/constants";
import { setUserInfo } from "@fas/cpa-state-manager/redux/actions";
import { getPersonalInfo } from "../../services/profileApi";
import { catchError } from "../../actions/error/actions";

export function* makeFetch(): Saga<void> {
  try {
    const { data: { id, firstName = "", lastName = "" } } = yield call(getPersonalInfo);

    yield put(setUserInfo({
      id,
      name: `${firstName} ${lastName}`,
    }));
  }
  catch (error) {
    yield put(catchError(error));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_USER_INFO, (makeFetch: Function));
}
