// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent, useEffect } from "react";
import {
  Box, CircularProgress, Grid, Paper, Typography,
} from "@mui/material";
import DocumentTitle from "react-document-title";
import { getTableData } from "@fas/cpa-state-manager/services/selectors/table";
import { bindActionCreators, type Dispatch } from "redux";
import { connect } from "react-redux";
import { getDashboardDataSaga } from "@fas/cpa-state-manager/redux/actions/dashboard";
import getLoadingState from "@fas/cpa-state-manager/services/selectors/loading";

import type {
  DispatchToProps, OwnProps, Props, StateToProps,
} from "./types/Dashboard.types";
import Chart from "../../components/Chart";
import { DASHBOARD_TABLE } from "../../helpers/constants";

const mapStateToProps: (state: *) => StateToProps = (state) => ({
  data: getTableData(state, DASHBOARD_TABLE),
  loading: getLoadingState(state, "getDashboardDataLoading"),
});

const mapDispatchToProps: (Dispatch<*>) => DispatchToProps = (dispatch) => bindActionCreators({
  onGetDashboardData: getDashboardDataSaga,
}, dispatch);

const Dashboard: StatelessFunctionalComponent<Props> = ({
  data = [],
  onGetDashboardData,
  loading,
}: Props) => {
  useEffect(() => {
    if (data.length === 0) {
      onGetDashboardData();
    }
  }, []);

  const isShowData: boolean = !loading;

  const chartPositionTop: string = "170px";
  const padding: string = "24px";
  const maxWidth: string = `calc((100vh - ${chartPositionTop} - ${padding}) * 2)`;
  return (
    <DocumentTitle title="Affiliate Dashboard">
      <Grid container maxWidth={maxWidth} minWidth="600px" margin="auto">
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" pb={2}>
            <Typography variant="h2">Dashboard</Typography>
          </Box>
          { loading && (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          )}
          { isShowData && (
            <>
              <Box component={Paper}>
                <Box pt={1} pl={3}>
                  <Typography variant="h4">Chart report for last 8 days</Typography>
                </Box>
                <Chart data={data} />
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </DocumentTitle>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(Dashboard);
