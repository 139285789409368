// @flow
import React, { useLayoutEffect, useRef, useState } from "react";
import { calculateTextWidth, getElementFont } from "@fas/ui-core/lib/utils";
import type { Node } from "react";
import {
  Autocomplete, Box, Chip, Link, autocompleteClasses, TextField,
} from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import type { UseStateType } from "@fas/ui-core/lib/SearchComponentsV2/helpers/types";

function isPlacedInside(items: string[], allowedWidth: number, font: string, offsetBetweenText: number = 26): boolean {
  return items.length === 1 || allowedWidth > (Math.ceil(calculateTextWidth(items.join(""), font)) + (offsetBetweenText * items.length));
}

const useStyles: () => * = makeStyles((theme) => ({
  tagContainer: {
    overflow: "hidden",
    minHeight: "33px",
    maxHeight: "33px",
  },
  tagContainerExpand: {
    maxHeight: "100%",
  },
  autocomplete: {
    [`& .${autocompleteClasses.inputRoot}`]: {
      cursor: "default",
      paddingTop: "1px",
    },
    [`& .${autocompleteClasses.input}`]: {
      display: "none",
    },
    [`& .${autocompleteClasses.tag}`]: {
      maxWidth: "calc(100% - 10px)",
      margin: "0px 5px 4px 5px",
      fontSize: "16px",
      background: theme.palette.background.gradient,
    },
  },
}));

type Props = {
  value: string | string[];
  dataTestId?: string,
  classes?: {
    item?: string,
  },
}

function ShowMoreInput({
  value,
  classes = {},
  dataTestId,
}: Props): Node {
  const showMoreItemsEl: { current: null | HTMLDivElement } = useRef(null);
  const chipRef: { current: null | HTMLDivElement } = useRef(null);
  const [allowedWidth, setAllowedWidth]: UseStateType<number> = useState(0);
  const [isShow, setIsShow]: UseStateType<boolean> = useState(false);
  const {
    tagContainer, tagContainerExpand, autocomplete,
  }: * = useStyles();

  const data: string[] = Array.isArray(value) ? value : [value];

  useLayoutEffect(() => {
    setAllowedWidth(Math.ceil(showMoreItemsEl.current ? showMoreItemsEl.current.clientWidth : 0));
  }, [showMoreItemsEl]);

  const font: string = chipRef.current ? getElementFont(chipRef.current) : "";
  const isPlaced: boolean = isPlacedInside(data, allowedWidth, font);

  const renderTags = (values: string[], getTagProps: ({ index: number }) => *): Node => (
    <Box display="flex" justifyContent="space-between" alignItems="flex-end" width={1}>
      <Box
        className={clsx(
          tagContainer,
          isShow && (tagContainerExpand)
        )}
      >
        {values.map((tag: string, index: number): Node => (
          <Chip
            ref={chipRef}
            {...getTagProps({ index })}
            data-testid={tag}
            label={tag}
            title={tag}
            deleteIcon={null}
            onDelete={null}
          />
        ))}
      </Box>
      { !isPlaced && (
        <Link style={{ cursor: "pointer" }} size="small" onClick={() => setIsShow(!isShow)}>{ isShow ? "less" : "more" }</Link>
      )}
    </Box>
  );

  return (
    <Autocomplete
      ref={showMoreItemsEl}
      className={clsx(autocomplete, classes.item)}
      multiple
      freeSolo
      readOnly
      options={[]}
      value={data}
      clearIcon={null}
      renderTags={renderTags}
      renderInput={(params) => (
        <TextField
          {...params}
          data-testid={dataTestId}
        />
      )}
    />
  );
}

export default ShowMoreInput;
