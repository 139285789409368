// @flow
import environment from "environment";
import { requestService } from "../request";
import type { FiltersApi } from "../dashboardApi";

export type ItemData = {|
  date: string,
  p1: string,
  hits: number,
  conversions: number,
  cr: number,
  epc: number,
  cost: number,
  cpa_installs: number,
  cpa_registrations: number,
  cpa_confirms: number,
  cpa_payments: number,
  offer_name: string,
  country: string,
  smartlink_type: string,
  platform: string,
  device_os: string,
|}

export type TotalData = $Shape<ItemData>;

export type Params = {
  limit: number,
  page: number,
  sorting?: {
    current: string,
    direction: string,
  }[],
  filters: FiltersApi,
  fields: $Keys<ItemData>[],
  group: [string],
}

export type Data = {
  data: ItemData[],
  total: TotalData[],
}
type DownloadParams = {
  sorting?: $PropertyType<Params, "sorting">,
  filters: $PropertyType<Params, "filters">,
  fields: { fieldName: $Keys<ItemData>, name: string}[],
}

export const downloadMainReport: (params: DownloadParams) => Promise<string> = (params) => requestService
  .post(environment.endpoints.post.downloadMainReport, params)
  .then(({ data }: { data: string }): string => data);

export const getMainReportList: (params: Params) => Promise<Data> = (params) => requestService
  .post(environment.endpoints.post.getMainReportData, params)
  .then(({ data }: { data: Data }): Data => data);
