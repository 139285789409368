// @flow
/* eslint-disable import/max-dependencies */
import React, { type Node, type StatelessFunctionalComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";
import {
  BrowserRouter as Router, Redirect as RouterRedirect, Route, Switch,
} from "react-router-dom";
import environment from "environment";
import {
  Box, Container, CssBaseline, Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { CabinetProvider } from "@fas/cpa-state-manager/services/CabinetProvider";
import { ConfirmProvider } from "material-ui-confirm";
import Postbacks from "../Postbacks";
import type {
  DispatchToProps, OwnProps, Props, StateToProps,
} from "./types/App.types";
import defaultTheme from "../../theme";
import PrivateRoute from "../../components/PrivateRoute";
import Navigation from "../../components/Navigation";
import { logoutRedirect } from "../../services/request";
import Dashboard from "../Dashboard";
import MainReport from "../MainReport";
import TransactionReport from "../TransactionReport/TransactionReport";
import PayoutHistoryReport from "../PayoutHistoryReport";
import Profile from "../Profile";
import Login from "../../components/Login";
import Unsubscribe from "../../components/Unsubscribe/Unsubscribe";
import NotificationsContainer from "../NotificationsContainer";

// $FlowFixMe
const mapStateToProps: (*) => StateToProps = () => ({});

// $FlowFixMe
const mapDispatchToProps: (Dispatch<*>) => DispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const useStyles = makeStyles(() => ({
  content: {
    height: "calc(100vh)",
    flexGrow: 1,
    minWidth: "500px",
  },
  body: {
    maxWidth: "1600px",
  },
  toolbar: {
    minHeight: "57px",
  },
}));

const App: StatelessFunctionalComponent<Props> = ({}: Props) => {
  const classes = useStyles();
  const routesWithoutNavigationAction: string[] = ["/login", "/restorePassword", "/changePassword", "/autologin", "/unsubscribe/:token"];
  return (
    <ConfirmProvider>
      <CabinetProvider environment={environment}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <NotificationsContainer />
            <Router>
              <Box display="flex">
                <Switch>
                  <Route
                    exact
                    path={routesWithoutNavigationAction}
                    component={() => <Navigation isShowAction={false} />}
                  />
                  <PrivateRoute path="*" component={(): Node => <Navigation isShowAction />} />
                </Switch>
                <main
                  className={classes.content}
                >
                  <Toolbar className={classes.toolbar} />
                  <Box component={Container} py={3} className={classes.body}>
                    <Switch>
                      <Route exact path="/unsubscribe/:token" component={Unsubscribe} />
                      <Route exact path={["/login", "/restorePassword", "/changePassword", "/autologin"]} component={Login} />
                      <PrivateRoute exact path="/dashboard" component={Dashboard} />
                      <PrivateRoute exact path="/postbacks" component={Postbacks} />
                      <PrivateRoute exact path="/profile" component={Profile} />
                      <PrivateRoute exact path="/payoutHistory" component={PayoutHistoryReport} />
                      <PrivateRoute exact path="/mainReport" component={MainReport} />
                      <PrivateRoute exact path="/transactionReport" component={TransactionReport} />
                      <PrivateRoute exact path="/logout" component={logoutRedirect} />
                      <PrivateRoute path="*" component={(): Node => <RouterRedirect to="/dashboard" />} />
                    </Switch>
                  </Box>
                </main>
              </Box>
            </Router>
          </ThemeProvider>
        </StyledEngineProvider>
      </CabinetProvider>
    </ConfirmProvider>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(App);
