// @flow
import { withStyles } from "@mui/styles";
import DateRangeDateCell from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangeDateCell";
import DateRangeDialog from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangeDialog";
import type { DateRangeFooterProps } from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangePicker.types";
import { Box, Button } from "@mui/material";
import clsx from "clsx";
import React from "react";
import SelectField from "./SelectField";
import useGroupBy from "./useGroupBy";

export const DialogComponent = withStyles(() => ({
  "@global": {
    ".MuiPickersCalendar-transitionContainer": {
      minHeight: "253px",
    },
    ".MuiDayPicker-weekContainer": {
      margin: 0,
    },
  },
}))(DateRangeDialog);

export const DateCellComponent = withStyles((theme) => ({
  root: {
    color: theme.palette.common.black,
    borderRadius: 0,
    width: "38px",
    height: "38px",
    margin: "1px 1px",
    padding: "0",
    fontSize: "12px",
    fontWeight: "400",
    "&:not(.Mui-selected)": {
      border: "none",
    },
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  startEndSelectedDay: {
    "&.Mui-selected": {
      background: "linear-gradient(96.34deg, rgba(64, 60, 244, 0.1) 0%, rgba(35, 180, 128, 0.1) 100%), #FFFFFF",
    },
    "&.Mui-selected:hover": {
      backgroundColor: "lightgrey",
    },
    "&.Mui-selected:focus": {
      backgroundColor: "inherit",
    },
    "& > span": {
      ...theme.typography.gradient,
      display: "inline-block",
    },
  },
  selectedDay: {
    "&.Mui-selected": {
      background: "linear-gradient(96.34deg, rgba(64, 60, 244, 0.1) 0%, rgba(35, 180, 128, 0.1) 100%), #FFFFFF",
      "& > span": {
        ...theme.typography.gradient,
        display: "inline-block",
      },
      "&:hover": {
        backgroundColor: "lightgrey",
      },
    },
  },
  otherMonth: {
    visibility: "hidden",
  },
}))(DateRangeDateCell);

export const FooterComponent = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  apply: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    padding: "10px 32px",
    "&:disabled": {
      backgroundColor: theme.palette.action.disabled,
    },
    background: "linear-gradient(96.34deg, rgba(64, 60, 244, 0.1) 0%, rgba(35, 180, 128, 0.1) 100%), #FFFFFF",
    "& > span": {
      ...theme.typography.gradient,
      display: "inline-block",
    },
  },
}))(({
  onApply,
  classes = {},
}: DateRangeFooterProps) => (
  <Box className={clsx(classes.root)}>
    <Button onClick={onApply} className={clsx(classes.apply)} data-testid="apply-btn">
      <span>Apply</span>
    </Button>
  </Box>
));

export const FooterComponentMainReport = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  apply: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    padding: "10px 32px",
    "&:disabled": {
      backgroundColor: theme.palette.action.disabled,
    },
    background: "linear-gradient(96.34deg, rgba(64, 60, 244, 0.1) 0%, rgba(35, 180, 128, 0.1) 100%), #FFFFFF",
    "& > span": {
      ...theme.typography.gradient,
      display: "inline-block",
    },
  },
}))(({
  onApply,
  classes = {},
}: DateRangeFooterProps) => {
  const { onApply: onApplyGroupBy, ...propsGroupBy } = useGroupBy();
  return (
    <Box className={clsx(classes.root)}>
      <SelectField {...propsGroupBy} />
      <Box>
        <Button
          onClick={() => {
            onApply();
            onApplyGroupBy();
          }}
          data-testid="apply-btn"
          className={clsx(classes.apply)}
        >
          <span>Apply</span>
        </Button>
      </Box>
    </Box>
  );
});
