// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
} from "@mui/material";
import Loader from "../Loader";
import type { Props } from "./types/ContactInfo.types";
import { LockIcon } from "../../icons";

const ContactInfo: StatelessFunctionalComponent<Props> = ({
  loading,
  companyName,
  website,
  address,
}: Props) => (
  <Loader loading={loading}>
    <Box mt={4}>
      <Box pb={2}>
        <Typography variant="h4">Contact Info</Typography>
      </Box>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <TextField
            id="companyName"
            name="companyName"
            label="Company Name"
            value={companyName}
            fullWidth
            disabled
            InputProps={{
              endAdornment: <LockIcon />,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="website"
            name="website"
            label="Company URL"
            value={website}
            fullWidth
            disabled
            InputProps={{
              endAdornment: <LockIcon />,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address"
            name="address"
            label="Address"
            value={address}
            fullWidth
            disabled
            InputProps={{
              endAdornment: <LockIcon />,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  </Loader>
);

export default ContactInfo;
