// @flow
import {
  Box, Checkbox, FormControl, FormControlLabel, FormLabel,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import React, { type Node } from "react";
import type { Option } from "@fas/cpa-state-manager/redux/reducers/dictionaries";

export type CheckBoxListFieldPropsType = {
  value: string[],
  options: Option[],
  onChange: ({ [string]: * }) => mixed,
  filterKey: string,
  label: string,
  disabled?: boolean,
  classes?: {
    formLabel: *,
  },
  // eslint-disable-next-line react/no-unused-prop-types
  "data-testid"?: string,
}

export default ({
  value = [],
  options = [],
  onChange,
  filterKey,
  label,
  classes = {},
  "data-testid": dataTestId,
  disabled,
}: CheckBoxListFieldPropsType): Node => {
  const { formLabel } = classes;
  const handleChange = (e) => {
    const newValue = e.target.checked ? [...value, e.target.name] : value.filter((v) => v !== e.target.name);
    onChange({ [filterKey]: newValue });
  };
  const currentValueOptions = value.filter((k) => options.some((option) => option.value === k));
  return (
    <FormControl>
      <FormLabel focused={false} className={formLabel}>{label}</FormLabel>
      <Box display="flex" alignItems="center" flexWrap="wrap" data-testid={dataTestId}>
        {options.map((option) => {
          const checked = value.includes(option.value);
          const isLastChecked = currentValueOptions.length === 1 && currentValueOptions[0] === option.value;
          const checkedIconStyle = isLastChecked || disabled ? {} : { fill: "url(#selected)" };
          return (
            <FormControlLabel
              disabled={isLastChecked || disabled}
              name={option.value}
              checked={checked}
              key={option.value}
              control={(
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon style={checkedIconStyle} fontSize="small" />}
                  checkedIcon={<CheckBoxIcon style={checkedIconStyle} fontSize="small" />}
                />
              )}
              label={option.title}
              onChange={handleChange}
            />
          );
        })}
      </Box>
    </FormControl>
  );
};
