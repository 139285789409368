// @flow

import type { Node } from "react";
import type { Column } from "../TableComponent/types/TableComponent.types";
import type { ItemData, TotalData } from "../../services/transactionReportApi";
import RenderNumber from "../MainReport/RenderNumber";
import type { Field } from "../../reducers/transactionReport/reducer";

type ColumnMap<T> = { [$Keys<T>]: $Shape<Column<T>> };

const columnMap: ColumnMap<ItemData> = {
  date: {
    sortable: true,
  },
  offer_name: {
    sortable: true,
  },
  is_declined: {
    render: ({ row }: { row: ItemData }): Node => String(row.is_declined),
  },
  decline_reason: {
  },
  p1: {
    sortable: true,
  },
  partner_click: {
    sortable: true,
  },
  country: {
    sortable: true,
  },
  platform: {
    sortable: true,
  },
  device_os: {
    sortable: true,
    render: ({ row, column }: { row: ItemData, column: Column<ItemData>}): Node => row[column.field] || "",
  },
  ip: {
    sortable: true,
  },
  cost: {
    sortable: true,
    render: RenderNumber,
  },
  tds_cid: {
    sortable: true,
  },
};

const totalColumnMap: ColumnMap<TotalData> = {
  date: {
    render: (): string => "Summary",
  },
  offer_name: {
    render: (): string => "-",
  },
  is_declined: {
    render: (): string => "-",
  },
  decline_reason: {
    render: (): string => "-",
  },
  p1: {
    render: (): string => "-",
  },
  partner_click: {
    render: (): string => "-",
  },
  country: {
    render: (): string => "-",
  },
  smartlink_type: {
    render: (): string => "-",
  },
  platform: {
    render: (): string => "-",
  },
  device_os: {
    render: (): string => "-",
  },
  ip: {
    render: (): string => "-",
  },
  cost: {
    render: RenderNumber,
  },
  tds_cid: {
    render: (): string => "-",
  },
};

// eslint-disable-next-line flowtype/no-weak-types
function mapColumns<T: Object>(fields: Field<T>[], map: ColumnMap<T>): Column<T>[] {
  return fields.map(({ field, label }: Field<T>): Column<T> => ({
    ...map[field],
    key: field,
    field,
    label,
  }));
}

// eslint-disable-next-line import/prefer-default-export
export function getColumns(
  fields: Field<ItemData>[]
): { columns: Column<ItemData>[], totalColumns: Column<TotalData>[] } {
  return {
    columns: mapColumns(fields, columnMap),
    totalColumns: mapColumns(fields, totalColumnMap),
  };
}
