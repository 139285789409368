/* eslint-disable import/max-dependencies */
// @flow
import type { Node } from "react";
import React from "react";
import {
  FormControl, FormLabel, TextField,
} from "@mui/material";
import useFormField from "@fas/ui-framework/lib/services/form/useFormField";
import { getPaymentByPaymentMethodId } from "@fas/cpa-cabinet-ui";
import type { PaymentData } from "@fas/cpa-cabinet-ui";
import { PAYMENT_METHOD_FORM } from "../../helpers/constants";
import { LockIcon } from "../../icons";

export type Props = {
  disabled?: boolean,
};

function SelectPaymentMethod({ disabled }: Props): Node {
  const {
    value: paymentMethodId,
  }: { value: mixed, onChange: (mixed) => mixed } = useFormField(PAYMENT_METHOD_FORM, "paymentMethodId");
  const paymentData: PaymentData | void = getPaymentByPaymentMethodId(String(paymentMethodId));

  return (
    <FormControl fullWidth>
      <FormLabel>Payment method</FormLabel>
      <TextField
        disabled={disabled}
        InputProps={{
          endAdornment: <LockIcon />,
        }}
        data-testid="paymentMethod"
        value={paymentData ? paymentData.label : ""}
      />
    </FormControl>
  );
}
SelectPaymentMethod.defaultProps = {
  disabled: false,
  isChanged: false,
};

export default SelectPaymentMethod;
