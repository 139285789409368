// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import {
  AppNavSidebar as AppNavSidebarComponent, AppNavSidebarAdditionalComponent,
  AppNavSidebarMenuComponent,
  type AppNavSidebarMenuComponentUrl,
  type AppNavSidebarProps,
} from "@fas/cpa-cabinet-ui";
import {
  ListItemIcon, MenuItem, type Theme, Toolbar,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import {
  AccountCircle, Assessment, CreditCard, Home, PowerSettingsNew, KeyboardArrowRight,
} from "@mui/icons-material";
import PostbackUrl from "../../icons/PostbackUrl";
import TransactionReport from "../../icons/TransactionReport";
import ManagerInfo from "../../containers/ManagerInfo";
import UserInfo from "../../containers/UserInfo";

type Props = {
  isOpen: boolean,
  onToggleExpand: () => void,
}

const useDrawerStyles: () => $PropertyType<AppNavSidebarProps, "classes"> = makeStyles(({
  paper: {
    justifyContent: "flex-start",
  },
  drawerOpen: {
    width: "213px",
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  listComponent: {
    flex: 1,
    paddingTop: 0,
  },
  menuItem: {
    "& .MuiSvgIcon-root": {
      fill: "black",
    },
    "& .MuiListItemText-root > .MuiTypography-root": {
      color: "rgba(0, 0, 0, 0.5)",
      lineHeight: "20px",
      fontWeight: 500,
    },
    "& .MuiListItemText-root": {
      paddingTop: "6px",
      paddingBottom: "6px",
    },
    "& .MuiListItemIcon-root": {
      minWidth: theme.spacing(5),
    },
    "&$selected": {
      background: "linear-gradient(96.34deg, rgba(64, 60, 244, 0.1) 0%, rgba(35, 180, 128, 0.1) 100%), #FFFFFF",
      borderLeft: "2px solid #403CF4",
      color: "white",
      "& .MuiSvgIcon-root": {
        fill: "url(#selected)",
      },
      "& .MuiListItemText-root > span": {
        ...theme.typography.gradient,
      },
    },
    "&:hover": {
      background: "linear-gradient(96.34deg, rgba(64, 60, 244, 0.1) 0%, rgba(35, 180, 128, 0.1) 100%), #FFFFFF",
    },
  },
  selected: {},
  toggleButton: {
    "& .MuiSvgIcon-root": {
      fill: "white",
    },
    minHeight: theme.spacing(6),
    background: "linear-gradient(96.34deg, #403CF4 0%, #23B480 100%)",
  },
}));

const Gradient = () => (
  <svg width={0} height={0}>
    <linearGradient id="selected" x1={0} y1={0} x2={19.7561} y2={2.19512} gradientUnits="userSpaceOnUse">
      <stop offset={0} stopColor="#403CF4" />
      <stop offset={1} stopColor="#23B480" />
    </linearGradient>
  </svg>
);

const ExpandButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <MenuItem onClick={onClick} className={classes.toggleButton}>
      <ListItemIcon>
        <KeyboardArrowRight />
      </ListItemIcon>
    </MenuItem>
  );
};

const Offset = withStyles((theme) => ({
  root: {
    minHeight: theme.spacing(7),
  },
}))(Toolbar);

const AdditionalComponentOpen = withStyles(() => ({
  additionalComponentOpen: {
    display: "visible",
  },
  additionalComponentClose: {
    display: "none",
  },
}))(AppNavSidebarAdditionalComponent);

const AdditionalComponentClose = withStyles(() => ({
  additionalComponentOpen: {
    display: "none",
  },
  additionalComponentClose: {
    display: "visible",
    left: 0,
  },
}))(AppNavSidebarAdditionalComponent);

const AppNavSidebar: StatelessFunctionalComponent<Props> = ({
  isOpen,
  onToggleExpand,
}: Props) => {
  const drawerClasses: $PropertyType<AppNavSidebarProps, "classes"> = useDrawerStyles();
  const classes = useStyles();
  const navBarClasses = {
    listComponent: classes.listComponent,
    menuItemClasses: {
      root: classes.menuItem,
      selected: classes.selected,
    },
  };

  const links: Array<AppNavSidebarMenuComponentUrl> = [
    { url: "/dashboard", text: "Dashboard", icon: <Home /> },
    { url: "/postbacks", text: "Postback URL", icon: <PostbackUrl /> },
    { url: "/payoutHistory", text: "Payout History", icon: <CreditCard /> },
    { url: "/mainReport", text: "Main Report", icon: <Assessment /> },
    { url: "/transactionReport", text: "Transaction Report", icon: <TransactionReport /> },
  ];
  const bottomLinks: Array<AppNavSidebarMenuComponentUrl> = [
    { url: "/profile", text: "My Account", icon: <AccountCircle /> },
    { url: "/logout", text: "Logout", icon: <PowerSettingsNew /> },
  ];

  return (
    <AppNavSidebarComponent
      permanentIconsVisible
      isOpen={isOpen}
      classes={drawerClasses}
    >
      <Gradient />
      <AdditionalComponentOpen>
        <Offset />
        <UserInfo onToggleExpand={onToggleExpand} />
        <ManagerInfo />
      </AdditionalComponentOpen>
      <AdditionalComponentClose>
        <Offset />
        <ExpandButton onClick={onToggleExpand} />
      </AdditionalComponentClose>
      <AppNavSidebarMenuComponent links={links} classes={navBarClasses} />
      <AppNavSidebarMenuComponent
        links={bottomLinks}
        classes={{
          menuItemClasses: {
            root: classes.menuItem,
            selected: classes.selected,
          },
        }}
      />
    </AppNavSidebarComponent>
  );
};

export default AppNavSidebar;
