// @flow
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table";
import {
  DOWNLOAD_MAIN_REPORT_SAGA,
  GET_MAIN_REPORT_SAGA, SET_MAIN_REPORT_EXPORT_FILTERS,
  SET_MAIN_REPORT_FIELDS,
  SET_MAIN_REPORT_GROUP,
  SET_MAIN_REPORT_IS_ADVANCED,
} from "../../helpers/constants";

export type GetMainReportDataSaga = {|
  type: typeof GET_MAIN_REPORT_SAGA,
|}

export type DownloadMainReportSaga = {|
  type: typeof DOWNLOAD_MAIN_REPORT_SAGA,
|}

export type SetMainReportFields = {|
  type: typeof SET_MAIN_REPORT_FIELDS,
  fields: string[],
|}

export type SetMainReportGroup = {|
  type: typeof SET_MAIN_REPORT_GROUP,
  groupBy: string,
|}

export type SetMainReportIsAdvanced = {|
  type: typeof SET_MAIN_REPORT_IS_ADVANCED,
  isAdvanced: boolean,
|}

export type SetMainReportExportFilters = {|
  type: typeof SET_MAIN_REPORT_EXPORT_FILTERS,
  filters: Filters,
|}

export type Actions = GetMainReportDataSaga
  | SetMainReportFields
  | DownloadMainReportSaga
  | SetMainReportIsAdvanced
  | SetMainReportExportFilters
  | SetMainReportGroup

export const getMainReportDataSaga: () => GetMainReportDataSaga = () => ({
  type: GET_MAIN_REPORT_SAGA,
});

export const downloadMainReportSaga: () => DownloadMainReportSaga = () => ({
  type: DOWNLOAD_MAIN_REPORT_SAGA,
});

export const setMainReportTableFields: (
  fields: string[],
) => SetMainReportFields = (fields) => ({
  type: SET_MAIN_REPORT_FIELDS,
  fields,
});

export function setMainReportIsAdvanced(isAdvanced: boolean): SetMainReportIsAdvanced {
  return {
    type: SET_MAIN_REPORT_IS_ADVANCED,
    isAdvanced,
  };
}

export function setMainReportExportFilters(filters: Filters): SetMainReportExportFilters {
  return {
    type: SET_MAIN_REPORT_EXPORT_FILTERS,
    filters,
  };
}

export function setMainReportGroup(groupBy: string): SetMainReportGroup {
  return {
    type: SET_MAIN_REPORT_GROUP,
    groupBy,
  };
}
