// @flow
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { Dispatch } from "redux";
import {
  getLoadingState,
} from "@fas/cpa-state-manager/services/selectors";
import { getTrackingCodeSaga } from "@fas/cpa-state-manager/redux/actions";
import Postbacks from "../../components/Postbacks";
import type {
  Props,
  OwnProps,
  StateToProps,
  DispatchToProps,
} from "./types/Postbacks.types";

const mapStateToProps: (state: *) => StateToProps = (state) => ({
  isGetDefaultPostbackLoading: getLoadingState(state, "getDefaultPostbackLoading"),
});

const mapDispatchToProps: (Dispatch<*>) => DispatchToProps = (dispatch) => bindActionCreators({
  onGetTrackingCode: getTrackingCodeSaga,
}, dispatch);

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(Postbacks);
