// @flow
/* eslint-disable import/max-dependencies */
import React, {
  Fragment,
  type Element,
} from "react";
import DocumentTitle from "react-document-title";
import AppContainer from "../../containers/App";

function App(): Element<typeof Fragment> {
  return (
    <DocumentTitle title="">
      <AppContainer />
    </DocumentTitle>
  );
}

export default App;
