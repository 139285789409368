// @flow
import type { Saga } from "redux-saga";
import {
  put,
  takeEvery,
  select,
} from "redux-saga/effects";
import { addNotification } from "@fas/cpa-state-manager/redux/actions/notifications";
import { getNotificationsList } from "@fas/cpa-state-manager/services/selectors/notifications";
import type { Notification } from "@fas/cpa-state-manager/redux/reducers/notifications";
import { CATCH_ERROR } from "../../helpers/constants";
import type { CatchErrorType } from "../../actions/error/actions";

// eslint-disable-next-line require-yield
export function* makeFetch(action: CatchErrorType): Saga<void> {
  /* eslint-disable no-console */
  console.error(action.error);
  const notifications: Notification[] = yield select(getNotificationsList);
  if (notifications.length === 0) {
    yield put(addNotification({
      message: "Something went wrong...",
      severity: "error",
    }));
  }
}

export default function* watchError(): Saga<void> {
  yield takeEvery(CATCH_ERROR, (makeFetch: Function));
}
