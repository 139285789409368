// @flow
/* eslint-disable import/max-dependencies */
import dayjs, { type Dayjs } from "dayjs";
import en from "dayjs/locale/en-au";
import type { DropDownObjItemType, Option } from "@fas/cpa-state-manager/redux/reducers/dictionaries";
import type { Sorting } from "@fas/cpa-state-manager/redux/actions/table";
import isEqual from "lodash.isequal";
import type { Node } from "react";
import { Box, Checkbox, Chip } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import React from "react";
import type { Field } from "../reducers/transactionReport/reducer";
import type { Filters, FiltersApi } from "../services/dashboardApi";

dayjs.locale(en);

export function mapOptions({ value, label }: DropDownObjItemType): { value: string, title: string } {
  return {
    value,
    title: label,
  };
}

export const parsePercentage: (value: number) => string = (value) => (
  Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(value));

export const getGroupByDateRange: (string, string) => "day" | "month" | "year" = (from, to) => {
  const diffDays: number = Math.abs(dayjs(to).diff(from, "d"));
  if (diffDays > 365) {
    return "year";
  }
  if (diffDays > 31) {
    return "month";
  }

  return "day";
};

export function formatDateByGroup(date: string, group: "day" | "month" | "year"): string {
  switch (group) {
    case "day": return dayjs(date).format("YYYY-MM-DD");
    case "month": return dayjs(date).format("YYYY-MM");
    case "year": return dayjs(date).format("YYYY");
    default: return date;
  }
}

/**
 * Prepare state filters for api filters
 * @param {Object} filters to convert
 * @returns {Filters} prepared filters to api
 */
export function prepareFilters({ dateTo, dateFrom, ...filters }: Filters): FiltersApi {
  const keyConvertMap: { [string]: (mixed) => mixed } = {};

  function defaultConvert(v: mixed): mixed | Array<mixed> {
    return (Array.isArray(v) ? v : [v]);
  }

  return Object.keys(filters).reduce((acc: FiltersApi, key: string): FiltersApi => {
    const value = Array.isArray(filters[key]) ? filters[key][0] : filters[key];
    if (value || value === false || value === 0) {
      return {
        ...acc,
        [key]: (keyConvertMap[key] || defaultConvert)(filters[key]),
      };
    }
    return acc;
  }, {
    date: [{ from: dateFrom, to: dateTo }],
  });
}

export function prepareFields<T: Object>(fields: Field<T>[]): $Keys<T>[] {
  return fields.map(({ field }: Field<T>): $Keys<T> => field);
}

export function prepareFieldsToExport<T: Object>(fields: Field<T>[]): { fieldName: $Keys<T>, name: string }[] {
  return fields
    .map(({ field, label }: Field<T>): { fieldName: $Keys<T>, name: string } => ({ fieldName: field, name: label }));
}

// eslint-disable-next-line max-len
export function prepareSorting<T: Object>(tableSorting: Sorting, fields: Field<T>[]): { current: string, direction: string }[] | void {
  // $FlowFixMe entries return mixed type
  const entries: [[string, string]] = Object.entries(tableSorting);
  return entries
    .filter(([current]) => prepareFields(fields).includes(current))
    .map(([current, direction]) => ({ current, direction }));
}

export function downloadCsv(data: string, name: string = "TransactionReport") {
  const hiddenElement: HTMLAnchorElement = document.createElement("a");
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`;
  hiddenElement.target = "_blank";
  hiddenElement.download = `${name}-${dayjs().format("dddd_MMMM_Do_YYYY_H_mm_ss")}.csv`;
  document.body && document.body.appendChild(hiddenElement); // for FF
  hiddenElement.click();
  hiddenElement.remove();
}

/**
 * Get min date to select in date range in all reports
 * @returns {dayjs.Dayjs} minDate
 */
export const getMinDate: () => Dayjs = (): Dayjs => dayjs("2022-12-01");

/**
 * Presets for date range
 * @type {{start: string, end: string, label: string, value: string}[]}
 */
export const presets: Array<{end: string, label: string, start: string, value: string}> = [
  {
    label: "Today",
    value: "today",
    start: dayjs().subtract(1, "s").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "Yesterday",
    value: "yesterday",
    start: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
  },
  {
    label: "Week to date",
    value: "weekToDate",
    start: dayjs().startOf("w").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "Month to date",
    value: "monthToDate",
    start: dayjs().startOf("M").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "Last week",
    value: "lastweek",
    start: dayjs().subtract(1, "w").startOf("w")
      .format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "w").endOf("w")
      .format("YYYY-MM-DD"),
  },
  {
    label: "Last month",
    value: "lastmonth",
    start: dayjs().subtract(1, "M").startOf("M").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "M").endOf("M").format("YYYY-MM-DD"),
  },
];

export const isEqualPreviousBuilder = () => {
  let oldVal;
  return function isEqualPrevious(currentVal: *): boolean {
    const isEqualValue = isEqual(currentVal, oldVal);
    oldVal = currentVal;
    return isEqualValue;
  };
};

export const formatNumber = (num: string | number) => Number(num).toFixed(4);

export const getDictionaryLoadingKey = (dictionary: string) => `${dictionary}Loading`;

export const renderOption = (props: *, option: Option, { selected }: { selected: boolean }): Node => (
  <li {...props} style={{ overflowWrap: "anywhere" }}>
    <Checkbox
      icon={<CheckBoxOutlineBlankIcon style={{ fill: "url(#selected)" }} fontSize="small" />}
      checkedIcon={<CheckBoxIcon style={{ fill: "url(#selected)" }} fontSize="small" />}
      style={{ marginRight: 8 }}
      checked={selected}
    />
    { option.title }
  </li>
);

export const renderTags = (values: Option[], getTagProps: ({ index: number }) => *): Node => {
  const numTags: number = values.length;
  const limitTags: number = 1;

  return (
    <Box display="flex" alignItems="center">
      {values.slice(0, limitTags).map(({ value, title }: Option, index: number) => (
        <Chip
          {...getTagProps({ index })}
          data-testid={value}
          size="small"
          label={title}
          key={value}
          style={{ maxWidth: 120 }}
          title={title}
        />
      ))}
      <Box paddingRight={0.5}>
        {numTags > limitTags && `+${numTags - limitTags}`}
      </Box>
    </Box>
  );
};
