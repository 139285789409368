// @flow
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table";
import {
  DOWNLOAD_TRANSACTION_REPORT_SAGA,
  GET_TRANSACTION_REPORT_SAGA, SET_TRANSACTION_REPORT_EXPORT_FILTERS,
  SET_TRANSACTION_REPORT_FIELDS,
  SET_TRANSACTION_REPORT_IS_ADVANCED,
} from "../../helpers/constants";

export type GetTransactionReportDataSaga = {|
  type: typeof GET_TRANSACTION_REPORT_SAGA,
|}

export type DownloadTransactionReportSaga = {|
  type: typeof DOWNLOAD_TRANSACTION_REPORT_SAGA,
|}

export type SetTransactionReportFields = {|
  type: typeof SET_TRANSACTION_REPORT_FIELDS,
  fields: string[],
|}

export type SetTransactionReportIsAdvanced = {|
  type: typeof SET_TRANSACTION_REPORT_IS_ADVANCED,
  isAdvanced: boolean,
|}

export type SetTransactionReportExportFilters = {|
  type: typeof SET_TRANSACTION_REPORT_EXPORT_FILTERS,
  filters: Filters,
|}

export type Actions = GetTransactionReportDataSaga
  | SetTransactionReportFields
  | DownloadTransactionReportSaga
  | SetTransactionReportIsAdvanced
  | SetTransactionReportExportFilters

export const getTransactionReportDataSaga: () => GetTransactionReportDataSaga = () => ({
  type: GET_TRANSACTION_REPORT_SAGA,
});

export const downloadTransactionReportSaga: () => DownloadTransactionReportSaga = () => ({
  type: DOWNLOAD_TRANSACTION_REPORT_SAGA,
});

export const setTransactionReportTableFields: (
  fields: string[],
) => SetTransactionReportFields = (fields) => ({
  type: SET_TRANSACTION_REPORT_FIELDS,
  fields,
});

export function setTransactionReportIsAdvanced(isAdvanced: boolean): SetTransactionReportIsAdvanced {
  return {
    type: SET_TRANSACTION_REPORT_IS_ADVANCED,
    isAdvanced,
  };
}

export function setTransactionReportExportFilters(filters: Filters): SetTransactionReportExportFilters {
  return {
    type: SET_TRANSACTION_REPORT_EXPORT_FILTERS,
    filters,
  };
}
