// @flow
/* eslint-disable import/max-dependencies */
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import {
  getTableData,
  getTableFilters,
  getTablePage,
  getTablePageSize,
  getTableSorting,
  getTableTotalAmount,
  getTableTotalData,
} from "@fas/cpa-state-manager/services/selectors/table";
import getLoadingState from "@fas/cpa-state-manager/services/selectors/loading";
import {
  changeTableFilters,
  changeTablePage,
  changeTablePageSize,
  changeTableSorting,
} from "@fas/cpa-state-manager/redux/actions/table";
import type {
  DispatchToProps, OwnProps, Props, StateToProps,
} from "./types/TransactionReport.types";
import TransactionReportComponent from "../../components/TransactionReport";
import { TRANSACTION_TABLE } from "../../helpers/constants";
import type { State } from "../../store";
import {
  downloadTransactionReportSaga,
  getTransactionReportDataSaga,
} from "../../actions/transactionReport";
import { getTransactionReportFields } from "../../selectors/transactionReport";

const mapStateToProps: (state: State) => StateToProps = (state) => ({
  data: getTableData(state, TRANSACTION_TABLE),
  totalData: getTableTotalData(state, TRANSACTION_TABLE),
  filters: getTableFilters(state, TRANSACTION_TABLE),
  sorting: getTableSorting(state, TRANSACTION_TABLE),
  // $FlowFixMe @fas/cpa-state-manager/services/selectors/loading fix type
  loading: getLoadingState(state, "getTransactionReportLoading") || getLoadingState(state, "downloadTransactionReportLoading"),
  pageSize: getTablePageSize(state, TRANSACTION_TABLE),
  page: getTablePage(state, TRANSACTION_TABLE),
  total: getTableTotalAmount(state, TRANSACTION_TABLE),
  fields: getTransactionReportFields(state),
});

const mapDispatchToProps: (Dispatch<*>) => DispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    onDownloadReport: downloadTransactionReportSaga,

    onChangeTablePage: changeTablePage,
    onChangeTablePageSize: changeTablePageSize,
    onChangeTableFilters: changeTableFilters,
    onChangeTableSorting: changeTableSorting,
    onGetData: getTransactionReportDataSaga,
  }, dispatch),
});

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(TransactionReportComponent);
