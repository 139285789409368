// @flow
import React, { type Node, type StatelessFunctionalComponent } from "react";
import {
  FormControl, FormLabel, Grid, TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import type { AdditionalPostback, Classes } from "./types/AdditionalPostbacks.types";
import ShowMoreInput from "./ShowMoreInput";

export type Props = {
  data: AdditionalPostback[],
  isLoading: boolean,
  classes?: Classes,
};

const useItemStyles: () => * = makeStyles(() => ({
  itemContainer: {
    display: "block",
  },
}));

type PostbackProps = {
  item: AdditionalPostback,
  classes?: Classes,
};

const Postback: StatelessFunctionalComponent<PostbackProps> = ({
  item,
  classes = {},
}) => {
  const {
    itemContainer,
  }: * = useItemStyles();
  const { offer, conversionType, country }: AdditionalPostback = item;
  const defaultValue: string[] = ["All"];

  return (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid container item xs={4} direction="column" className={clsx([itemContainer, classes.itemContainer])}>
        <FormControl fullWidth>
          <FormLabel className={classes.label}>
            Tracking code
          </FormLabel>
          <TextField
            InputProps={{
              readOnly: true,
            }}
            className={classes.item}
            data-testid="Tracking code"
            value={offer}
          />
        </FormControl>
      </Grid>
      <Grid container item xs={4} direction="column" className={clsx([itemContainer, classes.itemContainer])}>
        <FormControl fullWidth>
          <FormLabel className={classes.label}>
            Transaction type
          </FormLabel>
          <ShowMoreInput
            dataTestId="Transaction type"
            value={conversionType.length ? conversionType : defaultValue}
            classes={classes}
          />
        </FormControl>
      </Grid>
      <Grid container item xs={4} direction="column" className={clsx([itemContainer, classes.itemContainer])}>
        <FormControl fullWidth>
          <FormLabel className={classes.label}>
            Country
          </FormLabel>
          <ShowMoreInput
            dataTestId="Country"
            value={country.length ? country : defaultValue}
            classes={classes}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

const AdditionalPostbacks: StatelessFunctionalComponent<Props> = ({
  data,
  isLoading,
  classes = {},
}) => (
  <>
    {!isLoading && data.length !== 0 && (
      <Grid className={classes.container}>
        {data.map((item: AdditionalPostback): Node => (
          <Postback
            key={item.offer + Math.random()}
            item={item}
            classes={classes}
          />
        ))}
      </Grid>
    )}
  </>
);

export default AdditionalPostbacks;
