// @flow
import React, {
  type StatelessFunctionalComponent,
  type Node,
} from "react";
import {
  FormControl,
  FormLabel,
  Grid,
  Select,
  MenuItem,
  Box,
  TextField,
} from "@mui/material";
import type {
  Props,
  Option,
  EventPostbackType,
} from "./types/EventPostback.types";
import ShowMoreInput from "../AdditionalPostbacks/ShowMoreInput";

const defaultEventPostback: EventPostbackType = {
  id: "",
  name: "",
  type: "",
  url: "",
  country: [],
  isEditable: true,
};

const EventPostback: StatelessFunctionalComponent<Props> = ({
  data = defaultEventPostback,
  typeOptions = [],
  nameOptions = [],
  disabled,
  readOnly = true,
  classes = {},
}) => {
  const {
    name,
    type,
    url,
    country = [],
  }: EventPostbackType = data;

  return (
    <Grid container spacing={1} className={classes.container} data-testid={`${data.id ? data.id : "new"}-event-postback`}>
      <Grid item xs={5} className={classes.url}>
        <FormControl fullWidth>
          <FormLabel className={classes.label} htmlFor="text-field-url">
            Event code
          </FormLabel>
          <TextField
            id="text-field-url"
            disabled={disabled}
            InputProps={{
              readOnly,
            }}
            className={classes.input}
            data-testid="eventUrl"
            value={url}
          />
        </FormControl>
      </Grid>
      <Grid item xs={2} className={classes.type}>
        <FormControl fullWidth>
          <FormLabel className={classes.label} htmlFor="autocomplete-type">
            Event type
          </FormLabel>
          <Select
            id="autocomplete-type"
            disabled={disabled}
            readOnly={readOnly}
            className={classes.select}
            value={type}
          >
            {typeOptions.map(({
              value,
              title,
            }: Option): Node => <MenuItem key={value} value={value}>{title}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2} className={classes.name}>
        <FormControl fullWidth>
          <FormLabel className={classes.label} htmlFor="autocomplete-name">
            Event
          </FormLabel>
          <Select
            id="autocomplete-name"
            disabled={disabled}
            readOnly={readOnly}
            className={classes.select}
            value={name}
          >
            {nameOptions.map(({
              value,
              title,
            }: Option): Node => <MenuItem key={value} value={value}>{title}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3} className={classes.country}>
        <FormControl fullWidth>
          <FormLabel className={classes.label} htmlFor="autocomplete-name">
            Country
          </FormLabel>
          <Box>
            <ShowMoreInput
              value={country.length ? country : ["All"]}
            />
          </Box>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default EventPostback;
