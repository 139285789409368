// @flow
/* eslint-disable import/max-dependencies */
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import getDashboardDataSaga from "./sagas/getDashboardData";
import watchGetDropdownListSaga from "./sagas/getDropdownList";
import watchGetDefaultPostbackSaga from "./sagas/getDefaultPostbackSaga";
import watchGetMainReportDataSaga from "./sagas/getMainReportData";
import watchGetTransactionReportDataSaga from "./sagas/getTransactionReportData";
import watchGetPayoutHistoryReportDataSaga from "./sagas/getPayoutHistoryReportData";
import watchGetPersonalInfoSaga from "./sagas/getPersonalInfo";
import watchGetContactInfoSaga from "./sagas/getContactInfo";
import watchGetManagerInfoSaga from "./sagas/getManagerInfo";
import getUserInfoSaga from "./sagas/getUserInfo";
import watchError from "./sagas/handleError";
import watchExportMainReportDataSaga from "./sagas/exportMainReportData";
import watchExportTransactionReportDataSaga from "./sagas/exportTransactionReportData";

export default function* mainSaga(): Saga<void> {
  return yield all([
    call(getDashboardDataSaga),
    call(watchGetDropdownListSaga),
    call(watchGetDefaultPostbackSaga),
    call(watchGetMainReportDataSaga),
    call(watchGetTransactionReportDataSaga),
    call(watchGetPayoutHistoryReportDataSaga),
    call(watchGetPersonalInfoSaga),
    call(watchGetContactInfoSaga),
    call(watchGetManagerInfoSaga),
    call(getUserInfoSaga),
    call(watchExportMainReportDataSaga),
    call(watchExportTransactionReportDataSaga),
    call(watchError),
  ]);
}
