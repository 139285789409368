// @flow
import type { Saga } from "redux-saga";
import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import { GET_PERSONAL_INFO_SAGA } from "@fas/cpa-state-manager/redux/constants";
import setLoading from "@fas/cpa-state-manager/redux/actions/loading";
import {
  setPersonalInfo,
} from "@fas/cpa-state-manager/redux/actions";
import { getPersonalInfo, type PersonalInfoResponse } from "../../services/profileApi";
import { catchError } from "../../actions/error/actions";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading("getPersonalInfoLoading", true));

    const response: PersonalInfoResponse = yield call(getPersonalInfo);
    const {
      data: {
        firstName,
        lastName,
        email,
        imType,
        imName,
        howFoundOut,
      },
    }: PersonalInfoResponse = response;

    // $FlowFixMe make payload as shape of a state
    yield put(setPersonalInfo({
      firstName,
      lastName,
      email,
      messenger: imType,
      messengerId: imName,
      howFindAboutUs: howFoundOut,
    }));
  }
  catch (error) {
    yield put(catchError(error));
  }
  finally {
    yield put(setLoading("getPersonalInfoLoading", false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_PERSONAL_INFO_SAGA, (makeFetch: Function));
}
