// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  TablePagination,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

type Props = {
  page: number,
  pageSize: number,
  // eslint-disable-next-line react/require-default-props
  rowsPerPageOptions?: number[],
  count: number,
  onChangePage: (value: number) => void,
  // eslint-disable-next-line react/require-default-props
  onChangePageSize?: (value: number) => void | null,
}

type Classes = {
  root: string,
  selectLabel: string,
  displayedRows: string,
}

const useStyles: () => Classes = makeStyles((theme): {[key: $Keys<Classes>]: mixed} => ({
  root: {
    ...theme.typography.h6,
  },
  selectLabel: {
    ...theme.typography.body1,
  },
  displayedRows: {
    ...theme.typography.body1,
  },
}));

const Pagination: StatelessFunctionalComponent<Props> = ({
  page,
  pageSize,
  count,
  onChangePage,
  onChangePageSize,
  rowsPerPageOptions = [15, 25, 50, 100],
}: Props) => {
  const classes: Classes = useStyles();
  const currentPage: number = page - 1;

  const handleChangePage: (SyntheticMouseEvent<HTMLButtonElement>, number) => void = (event, newPage) => {
    onChangePage(newPage + 1);
  };

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      classes={classes}
      component="div"
      count={count}
      rowsPerPage={pageSize}
      page={currentPage}
      onPageChange={handleChangePage}
      onRowsPerPageChange={onChangePageSize && (({ target: { value } }) => onChangePageSize(value))}
      data-testid="tablePaginationBlock"
      SelectProps={{
        "data-testid": "rows-per-page-select",
      }}
      getItemAriaLabel={(type: 'first' | 'last' | 'next' | 'previous'): string => {
        const map: {[string]: string} = {
          first: "First page",
          last: "Last page",
          next: "Next page",
          previous: "Previous page",
        };

        return map[type];
      }}
    />
  );
};

export default Pagination;
