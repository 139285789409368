// @flow

import type { Node } from "react";
import type { Column } from "../TableComponent/types/TableComponent.types";
import type { ItemData, TotalData } from "../../services/mainReportApi";
import RenderNumber from "./RenderNumber";
import type { Field } from "../../reducers/transactionReport/reducer";
import ConversionButton from "./ConversionButton";

const RenderValueOrDash = ({ row, column }: { row: ItemData, column: Column<ItemData>}): Node => row[column.field] || "-";

type ColumnMap<T> = { [$Keys<T>]: $Shape<Column<T>> };

const columnMap: ColumnMap<ItemData> = {
  date: {
    sortable: true,
  },
  offer_name: {
    sortable: true,
  },
  p1: {
    sortable: true,
  },
  country: {
    sortable: true,
  },
  platform: {
    sortable: true,
  },
  device_os: {
    sortable: true,
    render: ({ row, column }: { row: ItemData, column: Column<ItemData>}): Node => row[column.field] || "",
  },
  hits: {
    sortable: true,
  },
  conversions: {
    sortable: true,
    render: ConversionButton,
  },
  cr: {
    sortable: true,
    render: RenderNumber,
  },
  epc: {
    sortable: true,
    render: RenderNumber,
  },
  cost: {
    sortable: true,
    render: RenderNumber,
  },
  cpa_confirms: {
    sortable: true,
    render: RenderValueOrDash,
  },
  cpa_registrations: {
    sortable: true,
    render: RenderValueOrDash,
  },
  cpa_installs: {
    sortable: true,
    render: RenderValueOrDash,
  },
  cpa_payments: {
    sortable: true,
    render: RenderValueOrDash,
  },
};

const totalColumnMap: ColumnMap<TotalData> = {
  date: {
    render: (): string => "Summary",
  },
  offer_name: {
    render: (): string => "-",
  },
  p1: {
    render: (): string => "-",
  },
  country: {
    render: (): string => "-",
  },
  smartlink_type: {
    render: (): string => "-",
  },
  platform: {
    render: (): string => "-",
  },
  device_os: {
    render: (): string => "-",
  },
  hits: {
  },
  conversions: {
  },
  cr: {
    render: RenderNumber,
  },
  epc: {
    render: RenderNumber,
  },
  cost: {
    render: RenderNumber,
  },
  cpa_confirms: {
    render: RenderValueOrDash,
  },
  cpa_registrations: {
    render: RenderValueOrDash,
  },
  cpa_installs: {
    render: RenderValueOrDash,
  },
  cpa_payments: {
    render: RenderValueOrDash,
  },
};

// eslint-disable-next-line flowtype/no-weak-types
function mapColumns<T: Object>(fields: Field<T>[], map: ColumnMap<T>): Column<T>[] {
  return fields.map(({ field, label }: Field<T>): Column<T> => ({
    ...map[field],
    key: field,
    field,
    label,
  }));
}

// eslint-disable-next-line import/prefer-default-export
export function getColumns(
  fields: Field<ItemData>[]
): { columns: Column<ItemData>[], totalColumns: Column<TotalData>[] } {
  return {
    columns: mapColumns(fields, columnMap),
    totalColumns: mapColumns(fields, totalColumnMap),
  };
}
