// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Box, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Redirect, Route } from "react-router-dom";
import type { ContextRouter } from "react-router-dom";
import {
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { isAuthenticated } from "../../services/request";
import AuthField from "./AuthField";
import RestorePassword from "./RestorePassword";
import ChangePassword from "./ChangePassword";
import Autologin from "./Autologin";

type Props = {
  location: $PropertyType<ContextRouter, "location">,
}

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const Login: StatelessFunctionalComponent<Props> = ({ location }: Props) => {
  const classes = useStyles();

  if (isAuthenticated()) {
    const { from } = location.state || { from: { pathname: "/" } };
    return <Redirect to={from} />;
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lf-NOIeAAAAAI1MzsYU2nIQGzq300SFFhw55Q-Z">
      <Container component="main" maxWidth="xs" className={classes.container}>
        <Box display="flex" flexDirection="column" alignItems="center" p={2} boxShadow={2}>
          <Route
            exact
            path="/login"
            component={AuthField}
          />
          <Route
            exact
            path="/autologin"
            component={Autologin}
          />
          <Route
            exact
            path="/restorePassword"
            component={RestorePassword}
          />
          <Route
            exact
            path="/changePassword"
            component={ChangePassword}
          />
        </Box>
      </Container>
    </GoogleReCaptchaProvider>
  );
};

export default Login;
