// @flow
/* eslint-disable import/max-dependencies */
import {
  call, put, select, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSorting } from "@fas/cpa-state-manager/services/selectors/table";
import setLoading from "@fas/cpa-state-manager/redux/actions/loading/actions";
import type { Sorting } from "@fas/cpa-state-manager/redux/actions/table";
import {
  downloadCsv,
  prepareFieldsToExport,
  prepareFilters,
  prepareSorting,
} from "../../helpers/generators";
import { DOWNLOAD_MAIN_REPORT_SAGA, MAIN_TABLE } from "../../helpers/constants";
import { getMainReportExportFilters, getMainReportFields, getMainReportGroupBy } from "../../selectors/mainReport";
import { setMainReportTableFields } from "../../actions/mainReport";
import { downloadMainReport } from "../../services/mainReportApi";
import { catchError } from "../../actions/error/actions";
import type { Field } from "../../reducers/transactionReport/reducer";
import type { ItemData } from "../../services/mainReportApi";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading("downloadMainReportLoading", true));
    const { dynamicFields, ...filters } = yield select(getMainReportExportFilters);

    const sorting: Sorting = yield select(getTableSorting, MAIN_TABLE);
    yield put(setMainReportTableFields(dynamicFields));
    const fields: Field<ItemData>[] = yield select(getMainReportFields);
    const groupBy: string = yield select(getMainReportGroupBy);

    const response: string = yield call(downloadMainReport, {
      filters: prepareFilters(filters),
      sorting: prepareSorting(sorting, fields),
      fields: prepareFieldsToExport(fields),
      group: [groupBy],
    });
    yield call(downloadCsv, response, "MainReport");
  }
  catch (error) {
    yield put(catchError(error));
  }
  finally {
    yield put(setLoading("downloadMainReportLoading", false));
  }
}

export default function* watchExportMainReportDataSaga(): Saga<void> {
  yield takeEvery(DOWNLOAD_MAIN_REPORT_SAGA, (makeFetch: Function));
}
