// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import {
  Box, IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PowerSettingsNew, Menu, Sort } from "@mui/icons-material";
import { LogoLight } from "../../icons";
import type { Props } from "./types/Header.types";

const useStyles = makeStyles((theme) => ({
  layout: {
    height: theme.spacing(7),
    zIndex: theme.zIndex.drawer + 1,
    position: "fixed",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  logo: {
    margin: "auto",
    height: "40px",
  },
}));

const Header = ({
  isExpand, onToggleExpand, isShowAction, onLogout,
}: Props) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.layout}>
        <Box
          p={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {isShowAction && (
            <IconButton name="menu" onClick={onToggleExpand} className={classes.menu} size="small">
              {isExpand ? <Menu /> : <Sort />}
            </IconButton>
          )}
          <Box className={classes.logo} display="flex" alignItems="center" mr={1}><LogoLight /></Box>

          {isShowAction && (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box
                data-testid="action_icons"
                className={classes.iconsRoot}
                mr={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <IconButton name="logout" onClick={onLogout} className={classes.icons} size="small">
                  <PowerSettingsNew />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Header;
