// @flow
/* eslint-disable import/max-dependencies */
import React, { type Node, type StatelessFunctionalComponent, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";
import { Grid, Typography } from "@mui/material";
import DocumentTitle from "react-document-title";
import { ContactInfo, PersonalInfo } from "@fas/cpa-state-manager";
import getLoadingState from "@fas/cpa-state-manager/services/selectors/loading";
import { getContactInfoSaga, getPersonalInfoSaga } from "@fas/cpa-state-manager/redux/actions";
import { withState } from "@fas/cpa-cabinet-ui";
import PersonalInfoComponent from "../../components/PersonalInfo";
import ContactInfoComponent from "../../components/ContactInfo";
import type {
  DispatchToProps, OwnProps, Props, StateToProps,
} from "./types/Profile.types";
import { usePaymentInfoForm } from "../../hoocks/usePaymentInfoForm";
import PaymentInfo from "../../components/PaymentInfo";

const mapStateToProps: (state: *) => StateToProps = (state) => ({
  personalInfoLoading: getLoadingState(state, "getPersonalInfoLoading"),
  contactInfoLoading: getLoadingState(state, "getContactInfoLoading"),
});

const mapDispatchToProps: (Dispatch<*>) => DispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    onGetContactInfo: getContactInfoSaga,
    onGetPersonalInfo: getPersonalInfoSaga,
  }, dispatch),
});

const PaymentInfoForm: StatelessFunctionalComponent<*> = withState(PaymentInfo, usePaymentInfoForm);

const Profile: StatelessFunctionalComponent<Props> = ({
  personalInfoLoading,
  contactInfoLoading,
  onGetContactInfo,
  onGetPersonalInfo,
}: Props) => {
  useEffect(() => {
    onGetPersonalInfo();
    onGetContactInfo();
  }, [onGetPersonalInfo, onGetContactInfo]);

  return (
    <DocumentTitle title="Affiliate Profile">
      <Grid container>
        <Grid item xs={12} md={9} lg={6}>
          <Typography variant="h2">Profile</Typography>
          <PersonalInfo
            renderComponent={({
              firstName,
              lastName,
              email,
              messenger,
              messengerId,
              photoSrc,
              howFindAboutUs,
            }): Node => (
              <PersonalInfoComponent
                loading={personalInfoLoading}
                firstName={firstName}
                lastName={lastName}
                email={email}
                messenger={messenger}
                messengerId={messengerId}
                photoSrc={photoSrc}
                howFindAboutUs={howFindAboutUs}
              />
            )}
          />
          <ContactInfo
            renderComponent={({
              companyName,
              website,
              address,
            }): Node => (
              <ContactInfoComponent
                loading={contactInfoLoading}
                companyName={companyName}
                website={website}
                address={address}
              />
            )}
          />
          <PaymentInfoForm />
        </Grid>
      </Grid>
    </DocumentTitle>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(Profile);
