// @flow

import { withStyles } from "@mui/styles";
import { Lock } from "@mui/icons-material";

export default withStyles({
  root: {
    color: "black",
  },
})(Lock);
