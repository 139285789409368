// @flow
import React, {
  useState,
  type StatelessFunctionalComponent,
  type Node,
} from "react";
import {
  Box, Collapse, Divider, Paper, Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

type Props = {
  title: string,
  children: Node,
  initialOpen?: boolean,
}

type UseState<T> = [T, ((T => T) | T) => void]

type Classes = {
  collapseTitle: string,
}

const useStyles: () => Classes = makeStyles((): {[key: $Keys<Classes>]: mixed} => ({
  collapseTitle: {
    cursor: "pointer",
    backgroundColor: "rgba(63, 67, 238, 0.1)",
  },
}));

const CollapseCard: StatelessFunctionalComponent<Props> = ({ title, children, initialOpen = true }: Props) => {
  const classes: Classes = useStyles();
  const [open, setOpen]: UseState<boolean> = useState(initialOpen);
  const handleClick: () => void = () => {
    setOpen(!open);
  };

  return (
    <Paper>
      <Box data-testid={title} onClick={handleClick} className={classes.collapseTitle}>
        <Box px={2} py={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">{title}</Typography>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </Box>
      <Collapse data-testid={`content-${title}`} in={open}>
        <Divider />
        {children}
      </Collapse>
    </Paper>
  );
};

export default CollapseCard;
