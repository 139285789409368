// @flow
/* eslint-disable import/max-dependencies */
import { useEffect } from "react";
import isEqual from "lodash.isequal";
import { useDispatch, useSelector } from "react-redux";
import { useHandleRequest, type UseHandleRequestHook } from "@fas/cpa-state-manager/redux/hooks";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { prepareFetchErrors } from "@fas/ui-framework/lib/services/generators";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";
import { PAYMENT_METHOD_FORM } from "../../helpers/constants";
import { getPaymentInfo, postPaymentInfo } from "../../services/profileApi";
import type { PaymentInfoType } from "../../services/profileApi";

type PaymentOtherField = { paymentMethodId: number, isEdit: boolean };
type PaymentFieldFormType = $Diff<PaymentInfoType, PaymentOtherField>;

export type UsePaymentInfoFormHook = {
  isLoading: boolean,
  isChanged: boolean,
  onSave: () => mixed,
  reset: () => mixed,
};

export const usePaymentInfoForm: () => UsePaymentInfoFormHook = () => {
  const dispatch: <A>(A) => A = useDispatch();
  const {
    isLoading,
    changeLoading,
    onFail,
    onFinally,
  }: UseHandleRequestHook = useHandleRequest(PAYMENT_METHOD_FORM);
  const {
    // eslint-disable-next-line no-unused-vars
    isEdit,
    paymentMethodId,
    ...form
  }: PaymentInfoType = useSelector((state: *): * => getFormData(state, PAYMENT_METHOD_FORM));

  const clearForm: PaymentFieldFormType = Object.keys(form)
    .reduce((acc: PaymentFieldFormType, key: string): PaymentFieldFormType => ({
      ...acc,
      [key]: "",
    }), { ...form });

  const clearErrorForm: ErrorsMapper = Object.keys(form)
    .reduce((acc: ErrorsMapper, key: string): ErrorsMapper => ({
      ...acc,
      [key]: { message: "" },
    }), {});

  function loadForm() {
    changeLoading(true);

    getPaymentInfo()
      .then((data: PaymentInfoType): mixed => {
        dispatch(setErrors(clearErrorForm));
        dispatch(setFormData(PAYMENT_METHOD_FORM, data));
      })
      .catch(onFail)
      .finally(onFinally);
  }

  useEffect(() => {
    loadForm();
  }, []);

  const onSave: () => mixed = () => {
    changeLoading(true);

    postPaymentInfo({ ...form, paymentMethodId })
      .then((): mixed => {
        dispatch(addNotification({ severity: "success", message: "Payment method updated successfully" }));
        loadForm();
      })
      .catch(async (err: *) => {
        await onFail(err);

        if (err.isAxiosError
          && err.response
          && err.response.data
          && err.response.data.errors
          && Array.isArray(err.response.data.errors)) {
          dispatch(setErrors(prepareFetchErrors(err.response.data.errors)));
        }
      })
      .finally(() => {
        onFinally();
      });
  };

  const reset: () => mixed = () => {
    dispatch(setFormData(PAYMENT_METHOD_FORM, clearForm));
    dispatch(setErrors(clearErrorForm));
  };

  return {
    isLoading,
    onSave,
    reset,
    isChanged: !isEqual(clearForm, form),
  };
};
