// @flow
import environment from "environment";
import { requestService } from "../request";

export type PersonalInfoResponse = {
  data: {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    imType: string,
    imName: string,
    howFoundOut: string
  }
}

export type ContactInfoResponse = {
  data: {
    companyName: string,
    companyUrl: string,
    address: string,
  }
}

export type PaymentInfoType = {
  bankAccountName: string,
  bankAddress: string,
  beAddress: string,
  swiftCode: string,
  beCountry: number,
  beName: string,
  iban: string,
  payoutCountry: number,
  accountNumber: string,
  bankName: string,
  paymentNote: string,
  paxumMail: string,
  webmoneyWallet: string,
  ePayments: string,
  bitcoin: string,
  qiwi: string,
  yandex: string,
  capitalistWallet: string,
  payoneerWallet: string,
  payPalWallet: string,
  genome: string,
  paysera: string,
  trustpay: string,
  bilderlings: string,
  card: string,
  paymentMethodId: number,
  vatNumber: string,
  isEdit: boolean,
}

export type PaymentInfoResponse = {
  data: PaymentInfoType,
}

export const getPersonalInfo: () => Promise<PersonalInfoResponse> = () => requestService
  .get(environment.endpoints.get.personalInfo)
  .then(({ data }: { data: PersonalInfoResponse }): PersonalInfoResponse => data);

export const getContactInfo: () => Promise<ContactInfoResponse> = () => requestService
  .get(environment.endpoints.get.contactInfo)
  .then(({ data }: { data: ContactInfoResponse }): ContactInfoResponse => data);

export const getPaymentInfo: () => Promise<PaymentInfoType> = () => requestService
  .get(environment.endpoints.get.paymentInfo)
  .then(({ data }: { data: PaymentInfoResponse }): PaymentInfoType => data.data);

export const postPaymentInfo: ($Diff<PaymentInfoType, { isEdit: boolean }>) => Promise<*> = (data) => requestService
  .post(environment.endpoints.post.paymentInfo, data);
