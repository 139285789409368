// @flow
/* eslint-disable import/max-dependencies */
import {
  call, put, select, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setTableData, setTableTotalData, changeTableItemsTotalAmount } from "@fas/cpa-state-manager/redux/actions";
import {
  getTableFilters,
  getTablePage,
  getTablePageSize,
  getTableSorting,
} from "@fas/cpa-state-manager/services/selectors/table";
import setLoading from "@fas/cpa-state-manager/redux/actions/loading/actions";
import { changeTablePage } from "@fas/cpa-state-manager/redux/actions/table";
import type { Sorting } from "@fas/cpa-state-manager/redux/actions/table";
import {
  isEqualPreviousBuilder,
  prepareFields,
  prepareFilters,
  prepareSorting,
} from "../../helpers/generators";
import { GET_TRANSACTION_REPORT_SAGA, TRANSACTION_TABLE } from "../../helpers/constants";
import { getTransactionReportFields } from "../../selectors/transactionReport";
import { setTransactionReportExportFilters, setTransactionReportTableFields } from "../../actions/transactionReport";
import { getTransactionReportList } from "../../services/transactionReportApi";
import { catchError } from "../../actions/error/actions";
import type { Field } from "../../reducers/transactionReport/reducer";
import type { ItemData } from "../../services/transactionReportApi";

const isEqualPrevious = isEqualPreviousBuilder();
const isEqualPreviousSorting = isEqualPreviousBuilder();

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading("getTransactionReportLoading", true));
    const allFilters = yield select(getTableFilters, TRANSACTION_TABLE);
    const sorting: Sorting = yield select(getTableSorting, TRANSACTION_TABLE);

    if (!isEqualPrevious(allFilters) || !isEqualPreviousSorting(sorting)) {
      yield put(changeTablePage(TRANSACTION_TABLE, 1));
    }
    const { dynamicFields, ...filters } = allFilters;

    const limit: number = yield select(getTablePageSize, TRANSACTION_TABLE);
    const page: number = yield select(getTablePage, TRANSACTION_TABLE);
    yield put(setTransactionReportTableFields(dynamicFields));
    const fields: Field<ItemData>[] = yield select(getTransactionReportFields);

    const { data, count, total } = yield call(getTransactionReportList, {
      filters: prepareFilters(filters),
      sorting: prepareSorting(sorting, fields),
      fields: prepareFields(fields),
      limit,
      page,
    });
    yield put(setTableData(TRANSACTION_TABLE, data));
    yield put(changeTableItemsTotalAmount(TRANSACTION_TABLE, count));
    yield put(setTableTotalData(TRANSACTION_TABLE, total[0]));
    yield put(setTransactionReportExportFilters({ ...filters, dynamicFields }));
  }
  catch (error) {
    yield put(setTableData(TRANSACTION_TABLE, []));
    yield put(changeTableItemsTotalAmount(TRANSACTION_TABLE, 0));
    yield put(catchError(error));
  }
  finally {
    yield put(setLoading("getTransactionReportLoading", false));
  }
}

export default function* watchGetTransactionReportDataSaga(): Saga<void> {
  yield takeEvery(GET_TRANSACTION_REPORT_SAGA, (makeFetch: Function));
}
