// @flow
import React, { forwardRef, type Node, type Ref } from "react";
import { ButtonBase } from "@mui/material";

export default forwardRef<*, *>(({ children, ...p }: *, ref: Ref<*>): Node => {
  const isNeedWrap: boolean = Array.isArray(children) ? typeof children[0] === "string" : typeof children === "string";
  return (
    <ButtonBase component="button" {...p} ref={ref}>{isNeedWrap ? <span>{children}</span> : children}</ButtonBase>
  );
});
