// @flow
/* eslint-disable import/max-dependencies */
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";

import {
  getTableData,
  getTableFilters,
  getTablePage,
  getTablePageSize,
  getTableSorting, getTableTotalAmount,
} from "@fas/cpa-state-manager/services/selectors/table";
import getLoadingState from "@fas/cpa-state-manager/services/selectors/loading";
import {
  changeTableFilters,
  changeTablePage,
  changeTablePageSize,
  changeTableSorting,
} from "@fas/cpa-state-manager/redux/actions/table";
import type {
  DispatchToProps, OwnProps, Props, StateToProps,
} from "./types/PayoutHistoryReport.types";
import PayoutHistoryReportComponent from "../../components/PayoutHistoryReport";
import { PAYOUT_HISTORY_TABLE } from "../../helpers/constants";
import type { State } from "../../store";
import { getPayoutHistoryReportDataSaga } from "../../actions/payoutHistoryReport";

const mapStateToProps: (state: State) => StateToProps = (state) => ({
  data: getTableData(state, PAYOUT_HISTORY_TABLE),
  filters: getTableFilters(state, PAYOUT_HISTORY_TABLE),
  sorting: getTableSorting(state, PAYOUT_HISTORY_TABLE),
  // $FlowFixMe @fas/cpa-state-manager/services/selectors/loading fix type
  loading: getLoadingState(state, "getPayoutHistoryReportLoading"),
  pageSize: getTablePageSize(state, PAYOUT_HISTORY_TABLE),
  page: getTablePage(state, PAYOUT_HISTORY_TABLE),
  total: getTableTotalAmount(state, PAYOUT_HISTORY_TABLE),
});

const mapDispatchToProps: (Dispatch<*>) => DispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    onChangeTablePage: changeTablePage,
    onChangeTablePageSize: changeTablePageSize,
    onChangeTableFilters: changeTableFilters,
    onChangeTableSorting: changeTableSorting,
    onGetData: getPayoutHistoryReportDataSaga,
  }, dispatch),
});

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(PayoutHistoryReportComponent);
