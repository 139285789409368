// @flow
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getTableFilters } from "@fas/cpa-state-manager/services/selectors/table";
import { getMainReportGroupBy } from "../../selectors/mainReport";
import { setMainReportGroup } from "../../actions/mainReport";
import { MAIN_TABLE } from "../../helpers/constants";

function useGroupBy() {
  const initialValue: string = useSelector(getMainReportGroupBy);
  const [value, setValue] = useState(initialValue);
  const isHaveDateColumn: boolean = useSelector((state) => {
    const fields: string[] = (getTableFilters(state, MAIN_TABLE).dynamicFields: any);
    return fields.some((column: string): boolean => column === "date");
  });

  const dispatch = useDispatch();

  const onApply = () => {
    dispatch(setMainReportGroup(value));
  };
  const onChange = (val: string) => {
    setValue(val);
  };

  return {
    disabled: !isHaveDateColumn,
    onApply,
    onChange,
    value: isHaveDateColumn ? value : "",
    label: "Group by",
    "data-testid": "group",
    options: [{ title: "Day", value: "day" }, { title: "Week", value: "week" }, { title: "Month", value: "month" }, { title: "Year", value: "year" }],
    style: {
      width: "130px",
      marginTop: "0",
    },
  };
}

export default useGroupBy;
