// @flow
import React, {
  type Node,
} from "react";
import type { Sorting } from "@fas/cpa-state-manager/redux/actions/table";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  type Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import type {
  Column,
} from "./types/TableComponent.types";

type Props<T> = {
  columns: Array<Column<T>>,
  onChangeSorting?: (sorting: Sorting) => void,
  sorting: Sorting,
  classes: { headerRow?: string, headerCell?: string },
}

type Classes = {
  headerRow: string,
  headerCell: string,
  collapseIcon: string,
  headerCellSortLabel: string,
}

const useStyles: () => Classes = makeStyles((theme: Theme): {[key: $Keys<Classes>]: mixed} => ({
  headerRow: {
    background: "transparent",
  },
  headerCell: {
    textAlign: "center",
    padding: 10,
    color: theme.palette.text.subtitle,
  },
  collapseIcon: {
    width: "48px",
  },
  headerCellSortLabel: {
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
}));

function Head<T: Object>({
  columns,
  onChangeSorting,
  sorting = {},
  classes = {},
}: Props<T>): Node {
  const ownClasses: Classes = useStyles();

  return (
    <TableHead className={clsx(ownClasses.headerRow, classes && classes.headerRow)}>
      <TableRow>
        {columns.map((column: Column<T>): Node => (
          column.sortable ? (
            <TableCell
              key={column.key}
              size="small"
              className={clsx(ownClasses.headerCell, classes && classes.headerCell, column.className)}
              onClick={
                column.sortable && onChangeSorting
                  ? (): void => {
                    const { key }: Column<T> = column;
                    if (!sorting[key]) {
                      return onChangeSorting({ [key]: "asc" });
                    }
                    if (sorting[key] === "asc") {
                      return onChangeSorting({ [key]: "desc" });
                    }
                    if (sorting[key] === "desc") {
                      return onChangeSorting({});
                    } return onChangeSorting({});
                  }
                  : null
              }
            >
              <TableSortLabel
                direction={sorting[column.key]}
                active={Boolean(sorting[column.key])}
                hideSortIcon={column.sortable !== true}
                className={clsx(ownClasses.headerCellSortLabel)}
                data-testid={`sort-${column.key}`}
              >
                {typeof column.label === "function"
                  ? column.label({ column })
                  : column.label}
              </TableSortLabel>

            </TableCell>
          ) : (

            <TableCell
              key={column.key}
              size="small"
              className={clsx(ownClasses.headerCell, classes.headerCell, column.className)}
            >
              <span>
                {typeof column.label === "function"
                  ? column.label({ column })
                  : column.label}
              </span>
            </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  );
}

Head.defaultProps = {
  onChangeSorting: null,
};

export default Head;
