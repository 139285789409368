// @flow
import type { Saga } from "redux-saga";
import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import { GET_TRACKING_CODE_SAGA } from "@fas/cpa-state-manager/redux/constants";
import setLoading from "@fas/cpa-state-manager/redux/actions/loading";
import { setTrackingCode } from "@fas/cpa-state-manager/redux/actions";
import { getDefaultPostback, type DefaultPostbackResponse } from "../../services/trackingToolsApi";
import { catchError } from "../../actions/error/actions";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading("getDefaultPostbackLoading", true));

    const response: DefaultPostbackResponse = yield call(getDefaultPostback);
    const { data: { defaultPostback } }: DefaultPostbackResponse = response;

    yield put(setTrackingCode(defaultPostback));
  }
  catch (error) {
    yield put(catchError(error));
  }
  finally {
    yield put(setLoading("getDefaultPostbackLoading", false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_TRACKING_CODE_SAGA, (makeFetch: Function));
}
