// @flow
import RequestService from "@fas/ui-framework/lib/services/request";
import { Cookies } from "react-cookie";
import environment from "environment";

/**
 * Get authorization token
 * @returns {string} token or empty string
 */
export function getAuthToken(): string {
  const data: {token?: string} = new Cookies().get("authToken") || {};
  return data.token || "";
}

export function setAuthToken(token: string, expires: Date) {
  new Cookies().set("authToken", { token }, { expires });
}

const getAuthorizationHeaders: () => { [key: string]: mixed } = () => ({
  Authorization: `Bearer ${getAuthToken()}`,
});

export function redirect(url: string) {
  window.location.href = url;
}

export function logoutRedirect(): null {
  logout();
  redirect("/login");
  return null;
}

/**
 * Request service for all request
 * @type {Request}
 */
export const requestService: RequestService = new RequestService({
  withCredentials: false,
  timeout: 30000,
  customAttr: { getAuthorizationHeaders, redirect: logoutRedirect },
});

const authRequestService: RequestService = new RequestService({
  withCredentials: false,
  timeout: 30000,
  customAttr: { redirect: () => {} },
});

export type AuthResponse = {
  message: string,
  payload: {
    token: string,
    expires: number,
  },
  status: number,
}

export type MessageResponse = {
  message: string,
}

export function login(params: {login: string, password: string}): Promise<AuthResponse> {
  return authRequestService.post(environment.endpoints.post.login, params)
    .then(({ data }: { data: AuthResponse }): AuthResponse => data);
}

export function autologin(params: {token: string, email: string}): Promise<AuthResponse> {
  return authRequestService.post(environment.endpoints.post.autologin, params)
    .then(({ data }: { data: AuthResponse }): AuthResponse => data);
}

export function restorePassword(params: {email: string}): Promise<MessageResponse> {
  return authRequestService.post(environment.endpoints.post.restorePassword, params)
    .then(({ data }: { data: MessageResponse }): MessageResponse => data);
}

export function changePassword(params: {
  token: string, password: string, confirmation: string
}): Promise<MessageResponse> {
  return authRequestService.post(environment.endpoints.post.changePassword, params)
    .then(({ data }: { data: MessageResponse }): MessageResponse => data);
}

export function unsubscribe(params: { token: ?string }): Promise<MessageResponse> {
  return authRequestService.post(environment.endpoints.post.unsubscribe, params)
    .then(({ data }: { data: MessageResponse }): MessageResponse => data);
}

export function logout() {
  new Cookies().remove("authToken");
}

export function isAuthenticated(): boolean {
  return Boolean(getAuthToken());
}
