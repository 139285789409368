// @flow
import environment from "environment";
import { requestService } from "../request";

export type ManagerInfoPayload = {
  data: {
    name: string,
    email: string,
    skype: string,
    telegram: string,
  },
};

// eslint-disable-next-line import/prefer-default-export
export const getManagerInfo: () => Promise<ManagerInfoPayload> = () => requestService
  .get(environment.endpoints.get.managerContactInfo)
  .then(({ data }: { data: ManagerInfoPayload }): ManagerInfoPayload => data);
