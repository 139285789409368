// @flow
import environment from "environment";
import { requestService } from "../request";
import type { FiltersApi } from "../dashboardApi";

export type ItemData = {|
  date: string,
  partner_click: string,
  offer_name: string,
  is_declined: string,
  decline_reason: string,
  p1: string,
  country: string,
  smartlink_type: string,
  platform: string,
  device_os: string,
  ip: string,
  cost: number,
  tds_cid: string,
|}

export type TotalData = $Shape<ItemData>;

export type Params = {
  limit: number,
  page: number,
  sorting?: {
    current: string,
    direction: string,
  }[],
  filters: FiltersApi,
  fields: $Keys<ItemData>[],
}

export type Data = {
  data: ItemData[],
  total: TotalData[],
}

export const getTransactionReportList: (params: Params) => Promise<Data> = (params) => requestService
  .post(environment.endpoints.post.getTransactionReportData, params)
  .then(({ data }: { data: Data }): Data => data);

type DownloadParams = {
  sorting?: $PropertyType<Params, "sorting">,
  filters: $PropertyType<Params, "filters">,
  fields: { fieldName: $Keys<ItemData>, name: string}[],
}

export const downloadTransactionReport: (params: DownloadParams) => Promise<string> = (params) => requestService
  .post(environment.endpoints.post.downloadTransactionReport, params)
  .then(({ data }: { data: string }): string => data);
