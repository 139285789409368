// @flow
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table";
import dayjs from "dayjs";
import type { ItemData } from "../../services/mainReportApi";

function getFiltersFromRow(row: $Shape<ItemData>, {
  dateFrom: defaultDateFrom = dayjs().format("YYYY-MM-DD"),
  dateTo: defaultDateTo = dayjs().format("YYYY-MM-DD"),
  country: defaultCountry = [],
  smartlink_type: defaultSmartlinkType = "",
  offer_name: defaultBasicFunnelName = [],
  p1: defaultP1 = [],
  device_os: defaultDeviceOs = [],
  platform: defaultPlatform = "",
}: Filters): Filters {
  const [dateFrom, dateTo = dateFrom]: string[] = (row.date || "").split(" - ");

  return {
    // $FlowFixMe need update cabinet-ui type
    is_declined: false,
    dateFrom: dateFrom || defaultDateFrom,
    dateTo: dateTo || defaultDateTo,
    smartlink_type: row.smartlink_type ? row.smartlink_type.toLowerCase() : defaultSmartlinkType,
    country: row.country ? [row.country] : defaultCountry,
    offer_name: row.offer_name ? [row.offer_name] : defaultBasicFunnelName,
    p1: row.p1 ? [row.p1] : defaultP1,
    device_os: row.device_os ? [row.device_os] : defaultDeviceOs,
    platform: row.platform ? row.platform : defaultPlatform,
  };
}

export default getFiltersFromRow;
