// @flow
import type { RecordFactory, RecordOf } from "immutable";
import { Record } from "immutable";
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table";
import type { DropDownObjItemType } from "@fas/cpa-state-manager/redux/reducers/dictionaries";
import {
  SET_TRANSACTION_REPORT_EXPORT_FILTERS,
  SET_TRANSACTION_REPORT_FIELDS,
  SET_TRANSACTION_REPORT_IS_ADVANCED,
} from "../../helpers/constants";
import type {
  Actions,
  SetTransactionReportExportFilters,
  SetTransactionReportFields,
  SetTransactionReportIsAdvanced,
} from "../../actions/transactionReport";
// eslint-disable-next-line no-unused-vars
import type { ItemData } from "../../services/transactionReportApi";

export type Field<T: Object> = { field: $Keys<T>, label: string, visible?: boolean };

export type DefaultStateType = {
  fields: Field<ItemData>[],
  isAdvanced: boolean,
  exportFilters: Filters,
}
export const dynamicFieldsOptions: DropDownObjItemType[] = [
  { value: "date", label: "Date" },
  { value: "p1", label: "SubId" },
  { value: "country", label: "Country" },
  { value: "platform", label: "Device" },
  { value: "device_os", label: "OS" },
  { value: "ip", label: "IP" },
];

const defaultState: DefaultStateType = {
  isAdvanced: false,
  exportFilters: {},
  fields: [
    { field: "date", label: "Date", visible: true },
    { field: "offer_name", label: "Offers", visible: true },
    { field: "is_declined", label: "Status", visible: true },
    { field: "decline_reason", label: "Decline Reason", visible: true },
    { field: "p1", label: "SubID", visible: false },
    { field: "partner_click", label: "ClickID", visible: true },
    { field: "country", label: "Country", visible: false },
    { field: "platform", label: "Device", visible: false },
    { field: "device_os", label: "OS", visible: false },
    { field: "ip", label: "IP", visible: false },
    { field: "cost", label: "Payout $", visible: true },
  ],
};

export const makeState: RecordFactory<DefaultStateType> = Record(defaultState);

export type State = RecordOf<DefaultStateType>;

export const initialState: State = makeState();

export function initState({
  fields = defaultState.fields,
  isAdvanced = defaultState.isAdvanced,
  exportFilters = defaultState.exportFilters,
}: { fields?: Field<ItemData>[], isAdvanced?: boolean, exportFilters?: Filters } = defaultState): State {
  return initialState.merge(makeState({
    fields,
    isAdvanced,
    exportFilters,
  }));
}

export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_TRANSACTION_REPORT_FIELDS: {
      const { fields: dynamicFields }: SetTransactionReportFields = action;
      const isDynamic = (field) => dynamicFieldsOptions.some(({ value }) => value === field);
      return state.set("fields", initialState.get("fields").map((item) => ({
        ...item,
        visible: isDynamic(item.field) ? dynamicFields.includes(item.field) : item.visible,
      })));
    }
    case SET_TRANSACTION_REPORT_IS_ADVANCED: {
      const { isAdvanced }: SetTransactionReportIsAdvanced = action;
      return state.set("isAdvanced", isAdvanced);
    }
    case SET_TRANSACTION_REPORT_EXPORT_FILTERS: {
      const { filters }: SetTransactionReportExportFilters = action;
      return state.set("exportFilters", filters);
    }
    default:
      return state;
  }
};
