// @flow
/* eslint-disable import/max-dependencies */
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";

import {
  getTableData,
  getTableFilters,
  getTablePage,
  getTablePageSize,
  getTableSorting,
  getTableTotalAmount,
  getTableTotalData,
} from "@fas/cpa-state-manager/services/selectors/table";
import getLoadingState from "@fas/cpa-state-manager/services/selectors/loading";
import {
  changeTableFilters,
  changeTablePage,
  changeTablePageSize,
  changeTableSorting,
} from "@fas/cpa-state-manager/redux/actions/table";
import type {
  DispatchToProps, OwnProps, Props, StateToProps,
} from "./types/MainReport.types";
import MainReportComponent from "../../components/MainReport";
import { MAIN_TABLE } from "../../helpers/constants";
import type { State } from "../../store";
import {
  downloadMainReportSaga,
  getMainReportDataSaga,
} from "../../actions/mainReport";
import { getMainReportFields } from "../../selectors/mainReport";

const mapStateToProps: (state: State) => StateToProps = (state) => ({
  data: getTableData(state, MAIN_TABLE),
  totalData: getTableTotalData(state, MAIN_TABLE),
  filters: getTableFilters(state, MAIN_TABLE),
  sorting: getTableSorting(state, MAIN_TABLE),
  // $FlowFixMe @fas/cpa-state-manager/services/selectors/loading fix type
  loading: getLoadingState(state, "getMainReportLoading") || getLoadingState(state, "downloadMainReportLoading"),
  pageSize: getTablePageSize(state, MAIN_TABLE),
  page: getTablePage(state, MAIN_TABLE),
  total: getTableTotalAmount(state, MAIN_TABLE),
  fields: getMainReportFields(state),
});

const mapDispatchToProps: (Dispatch<*>) => DispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    onDownloadReport: downloadMainReportSaga,

    onChangeTablePage: changeTablePage,
    onChangeTablePageSize: changeTablePageSize,
    onChangeTableFilters: changeTableFilters,
    onChangeTableSorting: changeTableSorting,
    onGetData: getMainReportDataSaga,
  }, dispatch),
});

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(MainReportComponent);
