// @flow
import type { Saga } from "redux-saga";
import {
  put,
  call,
  takeEvery,
  select,
} from "redux-saga/effects";
import setLoading from "@fas/cpa-state-manager/redux/actions/loading/actions";
import type { GetDictionarySaga } from "@fas/cpa-state-manager/redux/actions/dictionaries";
import type { DropDownObjItemType, DropDownObjType, Option } from "@fas/cpa-state-manager/redux/reducers/dictionaries";
import { getDictionary } from "@fas/cpa-state-manager/services/selectors/dictionaries";
import { GET_DICTIONARY_SAGA } from "@fas/cpa-state-manager/redux/constants";
import { setDictionary } from "@fas/cpa-state-manager/redux/actions/dictionaries";
import { getDropdownList } from "../../services/dictionariesApi";
import { catchError } from "../../actions/error/actions";
import { getDictionaryLoadingKey } from "../../helpers/generators";

export function* makeFetch(action: GetDictionarySaga): Saga<void> {
  const { dictionary }: GetDictionarySaga = action;
  try {
    yield put(setLoading(getDictionaryLoadingKey(dictionary), true));

    const dicList: DropDownObjItemType[] = yield select(getDictionary, dictionary);
    if (dicList.length > 0) { // do not take if we already have
      return;
    }

    const data: Option[] = yield call(getDropdownList, dictionary);

    const payload: DropDownObjType = {
      [`${dictionary}`]: data.map(({ title: label, value }: Option): DropDownObjItemType => ({ label, value })),
    };

    yield put(setDictionary(payload));
  }
  catch (error) {
    yield put(catchError(error));
  }
  finally {
    yield put(setLoading(getDictionaryLoadingKey(dictionary), false));
  }
}

export default function* watchGetDropdownListSaga(): Saga<void> {
  yield takeEvery(GET_DICTIONARY_SAGA, (makeFetch: Function));
}
