// @flow
import environment from "environment";
import type { Dictionaries, Option } from "@fas/cpa-state-manager/redux/reducers/dictionaries";
import { requestService } from "../request";

// eslint-disable-next-line import/prefer-default-export,max-len
export const getDropdownList: (type: Dictionaries) => Promise<Option[]> = (dictionary) => requestService
  .get(environment.endpoints.get.getDropdownList, {
    params: { dictionary },
  }).then(({ data }: { data: Option[] }): Option[] => data);
