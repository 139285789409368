// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
} from "@mui/material";
import Loader from "../Loader";
import type { Props } from "./types/PersonalInfo.types";
import { LockIcon } from "../../icons";

const PersonalInfo: StatelessFunctionalComponent<Props> = ({
  firstName,
  lastName,
  email,
  loading,
  messenger,
  messengerId,
}: Props) => (
  <Loader loading={loading}>
    <Box mt={4}>
      <Box pb={2}>
        <Typography variant="h4">User Info</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            id="firstName"
            name="firstName"
            label="First Name"
            value={firstName}
            fullWidth
            disabled
            InputProps={{
              endAdornment: <LockIcon />,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="lastName"
            name="lastName"
            label="Last Name"
            value={lastName}
            fullWidth
            disabled
            InputProps={{
              endAdornment: <LockIcon />,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="email"
            name="email"
            label="Email"
            value={email}
            fullWidth
            disabled
            InputProps={{
              endAdornment: <LockIcon />,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="flex-end">
            <Box width={1} pr={1}>
              <TextField
                id="howFindAboutUs"
                name="howFindAboutUs"
                label="IM Handle"
                value={messenger}
                disabled
                fullWidth
              />
            </Box>
            <TextField
              id="messengerId"
              name="messengerId"
              label=""
              value={messengerId}
              disabled
              InputProps={{
                endAdornment: <LockIcon />,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  </Loader>
);

export default PersonalInfo;
