/* eslint-disable import/max-dependencies */
// @flow
import type { Node } from "react";
import React from "react";
import {
  FormControl, FormLabel, MenuItem, Select,
} from "@mui/material";
import type { Option } from "@fas/cpa-cabinet-ui";

export type Props = {
  options: Option[],
  onChange: (string) => mixed,
  label: string,
  value: string,
};

function SelectField({
  options, label, value, onChange, ...props
}: Props): Node {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Select
        {...props}
        labelId={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        MenuProps={{ keepMounted: true, disablePortal: true }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>{ option.title }</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectField;
