// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers, applyMiddleware, createStore, compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import Immutable from "immutable";
import {
  loadingReducer as loading,
  initLoadingState,
  type LoadingState,
} from "@fas/cpa-state-manager/redux/reducers/loading";
import {
  trackingToolsReducer as trackingTools,
  initTrackingToolsState,
  type TrackingToolsState,
} from "@fas/cpa-state-manager/redux/reducers/trackingTools";
import {
  tableReducer as tables,
  initTableState,
  type TableState,
} from "@fas/cpa-state-manager/redux/reducers/table";
import {
  personalInfoReducer as personalInfo,
  initPersonalInfoState,
  type PersonalInfoState,
} from "@fas/cpa-state-manager/redux/reducers/personalInfo";
import {
  contactInfoReducer as contactInfo,
  initContactInfoState,
  type ContactInfoState,
} from "@fas/cpa-state-manager/redux/reducers/contactInfo";
import type {
  LoadingActions,
  TrackingCodeActions,
  ContactInfoActions,
  PersonalInfoActions,
  ManagerInfoActions,
} from "@fas/cpa-state-manager/redux/actions";
import {
  initDictionariesState,
  dictionariesReducer as dictionaries,
  type DictionariesState,
} from "@fas/cpa-state-manager/redux/reducers/dictionaries";
import dayjs from "dayjs";
import {
  managerInfoReducer as managerInfo,
  initManagerInfoState,
  type ManagerInfoState,
} from "@fas/cpa-state-manager/redux/reducers/managerInfo";
import { initUserInfoState, userInfoReducer as userInfo, type UserInfoState } from "@fas/cpa-state-manager/redux/reducers/userInfo";
import notifications, { initNotificationsState, type State as NotificationsState } from "@fas/cpa-state-manager/redux/reducers/notifications";
import {
  eventsPostbackReducer as eventsPostback,
  type EventsPostbackState,
  initEventsPostbackState,
  additionalPostbackReducer as additionalPostback,
  initAdditionalPostbackState,
  type AdditionalPostbackState,
} from "@fas/cpa-state-manager/redux/reducers";
import { initState as initErrorsState, reducer as errors } from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import type { State as ErrorsState } from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import form, {
  initFormState,
  type State as FormState,
} from "@fas/ui-framework/lib/redux/reducers/form";
import mainSaga from "./saga";
import {
  DASHBOARD_TABLE,
  MAIN_TABLE,
  TRANSACTION_TABLE,
  PAYOUT_HISTORY_TABLE, PAYMENT_METHOD_FORM,
} from "./helpers/constants";
import mainReport, {
  initState as initMainReportState,
  type State as MainReportState,
} from "./reducers/mainReport";
import transactionReport, {
  initState as initTransactionReportState,
  type State as TransactionReportState,
} from "./reducers/transactionReport";

export type LoadingTypes = "getDefaultPostbackLoading"
  | "getDashboardDataLoading"
  | "getPersonalInfoLoading"
  | "getContactInfoLoading"
  | "getPaymentInfoLoading"
  | "getDropdownListLoading";

export type State = $ReadOnly<{|
  form: FormState,
  errors: ErrorsState,
  loading: LoadingState<LoadingTypes>,
  tables: TableState,
  dictionaries: DictionariesState,
  trackingTools: TrackingToolsState,
  personalInfo: PersonalInfoState,
  contactInfo: ContactInfoState,
  mainReport: MainReportState,
  transactionReport: TransactionReportState,
  managerInfo: ManagerInfoState,
  userInfo: UserInfoState,
  notifications: NotificationsState,
  additionalPostback: AdditionalPostbackState,
  eventsPostback: EventsPostbackState,
|}>;

export function mapState(): State {
  return {
    form: initFormState({
      [PAYMENT_METHOD_FORM]: {
        isEdit: true,
        paymentMethodId: 0,
        accountNumber: "",
        bankAccountName: "",
        bankAddress: "",
        bankName: "",
        beAddress: "",
        beCountry: "",
        beName: "",
        bitcoin: "",
        capitalistWallet: "",
        genome: "",
        iban: "",
        paxumMail: "",
        paymentNote: "",
        payoneerWallet: "",
        payoutCountry: "",
        payPalWallet: "",
        paysera: "",
        qiwi: "",
        swiftCode: "",
        vatNumber: "",
        webmoneyWallet: "",
        yandex: "",
        usdt: "",
      },
    }),
    errors: initErrorsState(),
    loading: initLoadingState({
      getDefaultPostbackLoading: false,
      getDashboardDataLoading: false,
      getPersonalInfoLoading: false,
      getContactInfoLoading: false,
      getPaymentInfoLoading: false,
      getDropdownListLoading: false,
    }),
    notifications: initNotificationsState(),
    tables: initTableState({
      [PAYOUT_HISTORY_TABLE]: {
        pageSize: 15,
        filters: {
          dateFrom: dayjs().subtract(7, "d").format("YYYY-MM-DD"),
          dateTo: dayjs().format("YYYY-MM-DD"),
        },
        sorting: {
          created: "desc",
        },
      },
      [DASHBOARD_TABLE]: {
        filters: {
          dateFrom: dayjs().subtract(7, "d").format("YYYY-MM-DD"),
          dateTo: dayjs().format("YYYY-MM-DD"),
        },
      },
      [MAIN_TABLE]: {
        pageSize: 15,
        filters: {
          dateFrom: dayjs().subtract(7, "d").format("YYYY-MM-DD"),
          dateTo: dayjs().format("YYYY-MM-DD"),
          dynamicFields: [
            "date",
            "hits",
            "conversions",
            "cr",
            "epc",
            "cost",
          ],
        },
        sorting: {
          date: "desc",
          offer_name: "asc",
        },
      },
      [TRANSACTION_TABLE]: {
        pageSize: 15,
        filters: {
          dateFrom: dayjs().subtract(7, "d").format("YYYY-MM-DD"),
          dateTo: dayjs().format("YYYY-MM-DD"),
          dynamicFields: [
            "date",
          ],
        },
        sorting: {
          date: "desc",
          offer_name: "asc",
        },
      },
    }),
    dictionaries: initDictionariesState(),
    trackingTools: initTrackingToolsState({ canEdit: false }),
    personalInfo: initPersonalInfoState(),
    contactInfo: initContactInfoState(),
    mainReport: initMainReportState(),
    transactionReport: initTransactionReportState(),
    managerInfo: initManagerInfoState(),
    userInfo: initUserInfoState(),
    additionalPostback: initAdditionalPostbackState(),
    eventsPostback: initEventsPostbackState(),
  };
}

export type Actions = LoadingActions<LoadingTypes>
  | TrackingCodeActions
  | PersonalInfoActions
  | ContactInfoActions
  | ManagerInfoActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  form,
  errors,
  loading,
  tables,
  dictionaries,
  trackingTools,
  personalInfo,
  contactInfo,
  mainReport,
  transactionReport,
  managerInfo,
  userInfo,
  notifications,
  additionalPostback,
  eventsPostback,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<
    State,
    Actions,
    Dispatch<Actions>
  >(reducers, mapState(), composeEnhancers(applyMiddleware(sagaMiddleware)));

  sagaMiddleware.run(mainSaga);

  return store;
};
