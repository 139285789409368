// @flow
import {
  Record,
} from "immutable";
import type { RecordFactory, RecordOf } from "immutable";
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table";
import type { DropDownObjItemType } from "@fas/cpa-state-manager/redux/reducers/dictionaries";
import {
  SET_MAIN_REPORT_EXPORT_FILTERS,
  SET_MAIN_REPORT_FIELDS,
  SET_MAIN_REPORT_GROUP,
  SET_MAIN_REPORT_IS_ADVANCED,
} from "../../helpers/constants";
import type {
  SetMainReportFields, Actions, SetMainReportIsAdvanced, SetMainReportExportFilters,
  SetMainReportGroup,
} from "../../actions/mainReport";
import type { ItemData } from "../../services/mainReportApi";
import type { Field } from "../transactionReport/reducer";

export type DefaultStateType = {
  fields: Field<ItemData>[],
  isAdvanced: boolean,
  groupBy: string,
  exportFilters: Filters,
}

export const dynamicFieldsOptions: DropDownObjItemType[] = [
  { value: "date", label: "Date" },
  { value: "smartlink_type", label: "Smartlink type" },
  { value: "offer_name", label: "Offers" },
  { value: "p1", label: "SubId" },
  { value: "country", label: "Country" },
  { value: "platform", label: "Device" },
  { value: "device_os", label: "OS" },
];

export const dynamicFieldsOptionsMetrics: DropDownObjItemType[] = [
  { value: "hits", label: "Clicks" },
  { value: "cpa_installs", label: "Installs" },
  { value: "cpa_registrations", label: "Registrations" },
  { value: "cpa_confirms", label: "Confirms" },
  { value: "conversions", label: "Conversions" },
  { value: "cpa_payments", label: "Sales" },
  { value: "cr", label: "CR %" },
  { value: "epc", label: "EPC $" },
  { value: "cost", label: "Total revenue $" },
];

const defaultState: DefaultStateType = {
  groupBy: "day",
  isAdvanced: false,
  exportFilters: {},
  fields: ([
    { field: "date", label: "Date", visible: true },
    { field: "smartlink_type", label: "Smartlink type", visible: false },
    { field: "offer_name", label: "Offers", visible: false },
    { field: "p1", label: "SubId", visible: false },
    { field: "country", label: "Country", visible: false },
    { field: "platform", label: "Device", visible: false },
    { field: "device_os", label: "OS", visible: false },
    { field: "hits", label: "Clicks", visible: false },
    { field: "cpa_installs", label: "Installs", visible: false },
    { field: "cpa_registrations", label: "Registrations", visible: false },
    { field: "cpa_confirms", label: "Confirms", visible: false },
    { field: "conversions", label: "Conversions", visible: false },
    { field: "cpa_payments", label: "Sales", visible: false },
    { field: "cr", label: "CR %", visible: false },
    { field: "epc", label: "EPC $", visible: false },
    { field: "cost", label: "Total revenue $", visible: false },
  ]),
};

const isDynamic = (field) => [
  ...dynamicFieldsOptions,
  ...dynamicFieldsOptionsMetrics,
].some(({ value }) => value === field);

export const makeState: RecordFactory<DefaultStateType> = Record(defaultState);

export type State = RecordOf<DefaultStateType>;

export const initialState: State = makeState();

export function initState({
  fields = defaultState.fields,
  isAdvanced = defaultState.isAdvanced,
  exportFilters = defaultState.exportFilters,
}: { fields?: Field<ItemData>[], isAdvanced?: boolean, exportFilters?: Filters } = defaultState): State {
  return initialState.merge(makeState({
    fields,
    isAdvanced,
    exportFilters,
  }));
}

export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_MAIN_REPORT_FIELDS: {
      const { fields: dynamicFields }: SetMainReportFields = action;
      return state.set("fields", initialState.get("fields").map((item) => ({
        ...item,
        visible: isDynamic(item.field) ? dynamicFields.includes(item.field) : item.visible,
      })));
    }
    case SET_MAIN_REPORT_IS_ADVANCED: {
      const { isAdvanced }: SetMainReportIsAdvanced = action;
      return state.set("isAdvanced", isAdvanced);
    }
    case SET_MAIN_REPORT_EXPORT_FILTERS: {
      const { filters }: SetMainReportExportFilters = action;
      return state.set("exportFilters", filters);
    }
    case SET_MAIN_REPORT_GROUP: {
      const { groupBy }: SetMainReportGroup = action;
      return state.set("groupBy", groupBy);
    }
    default:
      return state;
  }
};
