// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent, type Node } from "react";
import {
  ActivePaxumIcon, PaxumIcon,
} from "@fas/cpa-cabinet-ui/lib/icons";
import {
  Autocomplete,
  Box, FormControl, FormLabel, Select, TextField, Typography,
} from "@mui/material";
import { withHookPropsState, type PaymentInfoFormComponent, type PaymentInfoFormProps } from "@fas/cpa-cabinet-ui";
import { useDictionary } from "@fas/cpa-state-manager/redux/hooks";
import useFormField from "@fas/ui-framework/lib/services/form/useFormField";
import type { Props as FormProps } from "@fas/ui-framework/lib/services/form/withForm";
import withForm from "@fas/ui-framework/lib/services/form/withForm";
import { Form } from "@fas/ui-core";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";
import PaymentInfoForm from "@fas/cpa-cabinet-ui/lib/PaymentInfoForm/PaymentInfoForm";
import { withStyles } from "@mui/styles";
import {
  getTitleByValue,
} from "@fas/cpa-cabinet-ui/lib/FilterBuilder/defaultComponentMapping";
import type { AutocompleteRenderInputParams } from "@mui/lab";
import SelectPaymentMethod from "./SelectPaymentMethod";
import { PAYMENT_METHOD_FORM } from "../../helpers/constants";
import type { UsePaymentInfoFormHook } from "../../hoocks/usePaymentInfoForm";
import { LockIcon } from "../../icons";
import Paxum from "./Paxum";

const SelectField = withFormField(({
  filterKey, options, label, value, onChange, placeholder, isLoading, error, ...props
}) => (
  <FormControl>
    <FormLabel>{label}</FormLabel>
    <Autocomplete
      {...props}
      loading={isLoading}
      id={`autocomplete-${filterKey}`}
      options={options}
      value={{ value, title: getTitleByValue(value, options) }}
      getOptionLabel={(option) => String(option.title)}
      isOptionEqualToValue={(option, current) => current.value === option.value}
      onChange={
        (e, newValue) => onChange((newValue && newValue.value) || "")
      }
      renderInput={(params: AutocompleteRenderInputParams): Node => (
        <TextField
          {...params}
          InputProps={{
            endAdornment: <LockIcon />,
          }}
          placeholder={placeholder}
          error={Boolean(error)}
          helperText={error}
        />
      )}
    />
  </FormControl>
));
const TextFieldForm = withFormField(({
  label, value, onChange, error, ...props
}) => (
  <FormControl>
    <FormLabel>{label}</FormLabel>
    <TextField
      value={value}
      InputProps={{
        endAdornment: <LockIcon />,
      }}
      onChange={({ target: { value: newValue } }) => onChange(newValue)}
      error={Boolean(error)}
      helperText={error}
      {...props}
    />
  </FormControl>
));

const DictionarySelectFieldForm: $Call<
  typeof withHookPropsState, typeof Select, typeof useDictionary
> = withHookPropsState(SelectField, useDictionary);

const FormContainer: StatelessFunctionalComponent<FormProps> = withForm(Form);

const components: PaymentInfoFormComponent = {
  Button: () => null,
  TextFieldForm,
  DictionarySelectFieldForm,
  FormContainer,
};

type Props = UsePaymentInfoFormHook;

const StyledPaymentInfo: StatelessFunctionalComponent<
  PaymentInfoFormProps
> = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    "& > form > div:nth-child(1n)": {
      paddingBottom: theme.spacing(3),
    },
  },
}))(PaymentInfoForm);

const PaymentInfo: StatelessFunctionalComponent<Props> = ({
  onSave,
}: Props) => {
  const {
    value: paymentMethodId,
  }: $Call<typeof useFormField, "", ""> = useFormField(PAYMENT_METHOD_FORM, "paymentMethodId");

  if (!paymentMethodId) return null;

  return (
    <Box pt={4}>
      <Box pb={2}>
        <Typography variant="h4">Payout Details</Typography>
      </Box>
      <SelectPaymentMethod disabled />
      <StyledPaymentInfo
        disabled
        id={PAYMENT_METHOD_FORM}
        onSave={onSave}
        components={components}
        paymentMethodId={Number(paymentMethodId)}
        mapMethods={{
          "2": {
            id: 2,
            label: "Paxum",
            icon: <PaxumIcon />,
            iconActive: <ActivePaxumIcon />,
            Form: Paxum,
          },
        }}
      />
    </Box>
  );
};

export default PaymentInfo;
