// @flow
/* eslint-disable import/max-dependencies */
import {
  call, put, select, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setTableData, setTableTotalData, changeTableItemsTotalAmount } from "@fas/cpa-state-manager/redux/actions";
import {
  getTableFilters,
  getTablePage,
  getTablePageSize,
  getTableSorting,
} from "@fas/cpa-state-manager/services/selectors/table";
import setLoading from "@fas/cpa-state-manager/redux/actions/loading/actions";
import { changeTablePage } from "@fas/cpa-state-manager/redux/actions/table";
import type { Sorting } from "@fas/cpa-state-manager/redux/actions/table";
import {
  isEqualPreviousBuilder,
  prepareFields,
  prepareFilters,
  prepareSorting,
} from "../../helpers/generators";
import { GET_MAIN_REPORT_SAGA, MAIN_TABLE } from "../../helpers/constants";
import { getMainReportFields, getMainReportGroupBy } from "../../selectors/mainReport";
import { setMainReportExportFilters, setMainReportTableFields } from "../../actions/mainReport";
import { getMainReportList } from "../../services/mainReportApi";
import { catchError } from "../../actions/error/actions";
import type { Field } from "../../reducers/transactionReport/reducer";
import type { ItemData } from "../../services/mainReportApi";

const isEqualPrevious = isEqualPreviousBuilder();
const isEqualPreviousSorting = isEqualPreviousBuilder();

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading("getMainReportLoading", true));
    const allFilters = yield select(getTableFilters, MAIN_TABLE);
    const sorting: Sorting = yield select(getTableSorting, MAIN_TABLE);

    if (!isEqualPrevious(allFilters) || !isEqualPreviousSorting(sorting)) {
      yield put(changeTablePage(MAIN_TABLE, 1));
    }
    const { dynamicFields, ...filters } = allFilters;

    const limit: number = yield select(getTablePageSize, MAIN_TABLE);
    const page: number = yield select(getTablePage, MAIN_TABLE);
    yield put(setMainReportTableFields(dynamicFields));
    const fields: Field<ItemData>[] = yield select(getMainReportFields);
    const groupBy: string = yield select(getMainReportGroupBy);

    const { data, count, total } = yield call(getMainReportList, {
      filters: prepareFilters(filters),
      sorting: prepareSorting(sorting, fields),
      fields: prepareFields(fields),
      limit,
      page,
      group: [groupBy],
    });
    yield put(setTableData(MAIN_TABLE, data));
    yield put(changeTableItemsTotalAmount(MAIN_TABLE, count));
    yield put(setTableTotalData(MAIN_TABLE, total[0]));
    yield put(setMainReportExportFilters({ ...filters, dynamicFields }));
  }
  catch (error) {
    yield put(setTableData(MAIN_TABLE, []));
    yield put(changeTableItemsTotalAmount(MAIN_TABLE, 0));
    yield put(catchError(error));
  }
  finally {
    yield put(setLoading("getMainReportLoading", false));
  }
}

export default function* watchGetMainReportDataSaga(): Saga<void> {
  yield takeEvery(GET_MAIN_REPORT_SAGA, (makeFetch: Function));
}
