// @flow
import React, { type Node, type StatelessFunctionalComponent } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

type Props = {
  to: string,
  children: Node,
}

const useStyles = makeStyles(() => ({
  container: {
    textAlign: "center",
  },
  link: {
    textDecoration: "none",
    color: "#00000080",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
  },
}));

const ButtonLink: StatelessFunctionalComponent<Props> = ({ to, children }: Props) => {
  const classes = useStyles();
  return (
    <Box pt={2} className={classes.container}>
      <Link className={classes.link} to={to}>{children}</Link>
    </Box>
  );
};

export default ButtonLink;
