// @flow
import React from "react";

const Logo = () => (
  <svg width="150" height="26" viewBox="0 0 150 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M147.837 4.27818H147.534C147.08 4.27818 146.714 3.91214 146.714 3.46415V1.64215C146.714 1.29796 146.998 1.0166 147.344 1.0166H150V2.13111C150 3.31391 149.029 4.27818 147.837 4.27818Z" fill="#3959D9" />
      <path d="M140.066 25.9858H141.579C142.295 25.9858 142.878 25.4066 142.878 24.6937V14.5784C142.878 13.9474 142.68 13.3437 142.306 12.8329L134.034 1.53755C133.792 1.20429 133.398 1.00488 132.983 1.00488H130.168L140.066 14.5211V25.9858Z" fill="#1F1E24" />
      <path d="M119.15 25.9857H122.287L114.037 17.8044H114.34C116.641 17.8044 118.891 16.8648 120.515 15.2258C122.119 13.6086 122.981 11.4916 122.942 9.26259C122.865 4.71168 119.07 1.0103 114.483 1.0103H108.075L108.823 2.42529L108.84 2.45261C109.562 3.59687 110.541 3.59178 111.074 3.58901L111.082 3.58897H114.483C116.074 3.58897 117.562 4.21179 118.677 5.34542C119.789 6.47905 120.383 7.97326 120.344 9.55215C120.27 12.6853 117.587 15.234 114.365 15.234H110.901C110.364 15.234 109.904 15.5399 109.701 16.0316C109.497 16.5206 109.604 17.0587 109.984 17.4357L118.234 25.6087C118.479 25.8519 118.803 25.9857 119.15 25.9857Z" fill="#1F1E24" />
      <path d="M83.9036 25.9748H98.5509V24.5516C98.5509 23.8386 97.9676 23.2595 97.2494 23.2595H82.602V24.6827C82.602 25.3957 83.1854 25.9748 83.9036 25.9748Z" fill="#1F1E24" />
      <path d="M82.602 14.5101H96.0276C96.7458 14.5101 97.3292 13.931 97.3292 13.218V11.7948H83.9036C83.1854 11.7948 82.602 12.3739 82.602 13.0869V14.5101Z" fill="#1F1E24" />
      <path d="M82.602 3.72556H97.2494C97.9676 3.72556 98.5509 3.14645 98.5509 2.43349V1.0103H83.9036C83.1854 1.0103 82.602 1.58941 82.602 2.30237V3.72556Z" fill="#1F1E24" />
      <path d="M61.2322 25.9338H62.1953C62.7292 25.9338 63.2052 25.6142 63.4033 25.1198L73.1004 1.01303H70.9898C70.456 1.01303 69.9799 1.3299 69.7818 1.82433L61.711 21.8855L53.6073 1.8216C53.4091 1.3299 52.9331 1.0103 52.3993 1.0103H50.2832L60.0242 25.1225C60.2224 25.6142 60.6984 25.9338 61.2322 25.9338Z" fill="#1F1E24" />
      <g filter="url(#filter0_d)">
        <path d="M20.7699 26H22.8859L13.1559 1.81403C12.9578 1.3196 12.4817 1 11.9452 1H10.9793C10.4427 1 9.96943 1.3196 9.76855 1.8113L0 25.9973H2.11881C2.6554 25.9973 3.13144 25.6804 3.32956 25.186L11.4609 5.05649L19.5591 25.186C19.7572 25.6804 20.2333 26 20.7699 26Z" fill="#3959D9" />
        <path d="M27.7284 24.6095L27.7312 25.9945L32.5329 25.9836C35.8157 25.8879 38.8866 24.5467 41.1787 22.2112C43.4792 19.8674 44.745 16.7752 44.745 13.5027C44.745 10.2329 43.4764 7.14068 41.1787 4.79693C38.8866 2.45864 35.8157 1.12014 32.5329 1.0218L23.1138 1.00268L23.1083 2.38762C23.1083 2.72908 23.2404 3.05414 23.4853 3.29726C23.7302 3.54037 24.0549 3.67696 24.4016 3.67696L32.4641 3.69061C37.8409 3.85724 42.0538 8.16505 42.0538 13.5027C42.0538 18.8403 37.8409 23.1508 32.4751 23.3147L29.0217 23.3229C28.675 23.3229 28.3503 23.4568 28.1054 23.6999C27.8632 23.943 27.7284 24.2654 27.7284 24.6095Z" fill="#3959D9" />
      </g>
    </g>
    <defs>
      <filter id="filter0_d" x="-173" y="-172" width="390.745" height="371" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="86.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.58 0 0 0 0 1 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <clipPath id="clip0">
        <rect width="150" height="25" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
