// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import {
  requestService,
} from "../request";

export type DefaultPostbackResponse = {
  data: {
    defaultPostback: string,
  }
}

export const getDefaultPostback: () => PromiseResponse<mixed, DefaultPostbackResponse> = () => requestService
  .get(environment.endpoints.get.getDefaultPostback)
  .then(({ data }) => data);
