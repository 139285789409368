// @flow
import React, { type StatelessFunctionalComponent, type Node } from "react";
import {
  Box,
} from "@mui/material";
import { type UseEventsPostback } from "@fas/cpa-state-manager/redux/hooks/useEventsPostback";
import { makeStyles, withStyles } from "@mui/styles";
import type { EventPostbackType } from "@fas/cpa-state-manager/redux/reducers/eventsPostback";
import EventPostback, { type Props as EventPostbackProps } from "../EventPostback";

export type Props = UseEventsPostback;

export const disableUnderlineStyle = {
  "& .MuiInput-input": {
    cursor: "default",
    backgroundColor: "transparent",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42);",
  },
};

const StyledEventPostback: StatelessFunctionalComponent<EventPostbackProps> = withStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(1),
  },
  select: {
    marginTop: "0px!important",
  },
  url: {
    flexGrow: "1",
    maxWidth: "100%",
    flexBasis: "0",
    ...disableUnderlineStyle,
  },
  type: {
    ...disableUnderlineStyle,
  },
  name: {
    ...disableUnderlineStyle,
  },
  country: {
    ...disableUnderlineStyle,
  },
}))(EventPostback);

const useStyle = makeStyles(() => ({
  rootContainer: {
    "& > :nth-child(n+2) label.MuiFormLabel-root": {
      display: "none",
    },
  },
}));

const InformationalEventsPostbacks: StatelessFunctionalComponent<Props> = ({
  data,
  nameOptions,
  typeOptions,
  ...rest
}) => {
  const classes = useStyle();
  return (
    <Box className={classes.rootContainer}>
      { data.map((item: EventPostbackType, index: number): Node => (
        <StyledEventPostback
          key={item.id || index}
          data={item}
          {...rest}
          nameOptions={nameOptions.filter((option: *): boolean => option.param === item.type)}
          typeOptions={typeOptions}
        />
      )) }
    </Box>
  );
};

export default InformationalEventsPostbacks;
